export var FieldLogicalOperationNodeOperatorType;
(function (FieldLogicalOperationNodeOperatorType) {
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["AND"] = 0] = "AND";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["OR"] = 1] = "OR";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["LIKE"] = 2] = "LIKE";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["EQ"] = 3] = "EQ";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["GT"] = 4] = "GT";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["LT"] = 5] = "LT";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["GTE"] = 6] = "GTE";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["LTE"] = 7] = "LTE";
    FieldLogicalOperationNodeOperatorType[FieldLogicalOperationNodeOperatorType["IN"] = 8] = "IN";
})(FieldLogicalOperationNodeOperatorType || (FieldLogicalOperationNodeOperatorType = {}));
