import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { LocalizationLanguage } from "eyam-webui-models";

class LocalizationService extends BaseService {
    private readonly BASE_URL: string = "/api/Localization";

    getAllLanguages(): Promise<LocalizationLanguage[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetAllLanguages`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new LocalizationService();