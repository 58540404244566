import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Grid, Header, Form, Button, Icon, Statistic, Input, Radio, Dropdown } from "semantic-ui-react";
import { FormFieldGroup, DynamicForm, DynamicModalFormAction, DynamicModalForm, formatDate } from "eyam-webui-components";
import { EYDItemCount, CountStatus, EYDItemMasterDetail } from "eyd.models-ui";
import { ScannerEntry } from "../../../Controls/ScannerEntry";

import ItemCountService from "../../../../Services/ItemCountService";
import ItemMasterDetailService from "../../../../Services/ItemMasterDetailService";
import GlobalSettingsService from "../../../../Services/GlobalSettingsService";
import { BaseGlobalSettings } from "eyam-webui-models";

interface InfoAssetEditProps {
    currentItemCountID: number;
    loadingOverride: boolean;
    handleError: (msg) => void;
    handleCancelEditMode: () => void;
}

export const ConsumeItemCountEdit: React.FunctionComponent<InfoAssetEditProps> = (props) => {
    const { t } = useTranslation();
    const [itemCount, setItemCount] = useState<EYDItemCount>();
    const [itemMaster, setItemMaster] = useState<EYDItemMasterDetail>();

    const [scannedCode, setScannedCode] = useState<string>("");
    const [autoSubmit, setAutoSubmit] = useState<boolean>(false);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [dateFormat, setDateFormat] = useState<string>("DD-MM-YYYY");
    const [open, setOpen] = useState(false);

    const mounted = useRef(false);



    useEffect(() => {
        mounted.current = true;
        setIsProcessing(true);

        let load = async () => {
            if (props.currentItemCountID && props.currentItemCountID !== 0) {
                let cItemCount = await ItemCountService.getItemCount(props.currentItemCountID, true);
                let setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.DateFormat);

                if (mounted.current) {
                    if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                        setDateFormat(setting.settingValue.valueText);
                    }
                    if (cItemCount) {
                        if (cItemCount.countStatus === CountStatus.InProcess) {
                            setItemCount(cItemCount);
                            var cItemMaster = await ItemMasterDetailService.getItemMasterDetailBySKU(cItemCount.sku);
                            setItemMaster(cItemMaster);
                        } else {
                            props.handleError(t("PAGE_LABEL-PAGE_ASSET_STOCK_ERROR_NOT_IN_PROCESS"));
                        }
                    } else {
                        props.handleError(t("PAGE_LABEL-PAGE_ASSET_STOCK_ERROR_ALERT_NOT_FOUND"));
                    }
                }

            }
            setIsProcessing(false);
        };

        load();

        return () => {
            mounted.current = false;
        };
    }, [props.currentItemCountID]);

    const getFormGroups = (): FormFieldGroup[] => {
        const groups: FormFieldGroup[] = [
            {
                tabPane: { content: "Tab 1" },
                formFields: [
                    {
                        control: Radio,
                        label: t("GENERIC_LABEL-AUTO_SUBMIT"),
                        selection: "true",
                        inline: true,
                        toggle: true,
                        defaultChecked: autoSubmit,
                        onChange: autoSubmit ? () => setAutoSubmit(false) : () => setAutoSubmit(true)
                    },
                    {
                        label: t("CF_NAME-SKU"),
                        control: Input,
                        disabled: true,
                        value: itemCount?.sku
                    },
                    {
                        label: t("GENERIC_LABEL-NAME"),
                        control: Input,
                        disabled: true,
                        value: itemMaster?.displayName
                    },
                    {
                        label: t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_DATE_CREATED"),
                        control: Input,
                        disabled: true,
                        value: formatDate(itemCount?.dateCreated, dateFormat + " HH:mm")
                    }
                ]
            }
        ];

        return groups;
    };

    const getModalFormGroups = (): FormFieldGroup[] => {
        const groups: FormFieldGroup[] = [
            {
                tabPane: { content: "Tab 1" },
                formFields: [
                    {
                        control: Dropdown,
                        multiple: true,
                        fluid: true,
                        selection: "true",
                        disabled: true,
                        options: itemCount?.itemCountDetails?.map((d) => ({ key: d.id, value: d.id, text: d.itemID })),
                        value: itemCount?.itemCountDetails?.filter(d => d.scanned === false).map(d => d.id)
                    }
                ]
            }
        ];

        return groups;
    };

    const getFormActions = (): DynamicModalFormAction[] => {
        let actions: DynamicModalFormAction[] = [
            {
                key: "ModalCancelButton",
                content: t("GENERIC_BUTTON-CANCEL"),
                onClick: () => {
                    setOpen(false);
                }
            }
        ];

        return actions;
    };

    const isSNValid = (sn: string): boolean => {

        if (!sn) {
            return false;
        }

        return true;
    }

    const handleItemScan = async (scannedItemID: string) => {
        if (!autoSubmit) {
            setIsProcessing(true);
        }
        if (itemCount.isSerialized) {
            let fndItem = itemCount.itemCountDetails.find((itm) => itm.itemID === scannedItemID);

            if (fndItem && fndItem.scanned === false) {
                fndItem.scanned = true;
                await ItemCountService.upsertItemCountDetail(fndItem);
                setItemCount({ ...itemCount });
            }
        } else {
            if (scannedItemID === itemCount.sku) {
                itemCount.quantity++;
                await ItemCountService.upsertItemCount(itemCount);
                setItemCount({ ...itemCount });
            }
        }
        if (!autoSubmit) {
            setIsProcessing(false);
        }
    };

    return (
        <div className="info-asset-edit common-view">
            {props.loadingOverride || isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable>
                    <Grid.Row verticalAlign="middle">
                        <DynamicModalForm
                            open={open}
                            title={t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_REMAINING_ITEMS")}
                            modalFormGroups={getModalFormGroups()}
                            formActions={getFormActions()}
                            isProcessing={isProcessing}
                        />
                        <Grid.Column>
                            <Statistic
                                floated="right"
                                value={
                                    itemCount?.isSerialized
                                        ? `${itemCount?.itemCountDetails?.filter((itm) => itm.scanned).length}/${itemCount?.itemCountDetails?.length
                                        }`
                                        : `${itemCount?.quantity}/${itemCount?.expectedQuantity}`
                                }
                            />
                            <DynamicForm groups={getFormGroups()}></DynamicForm>
                            <Header>{t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_SCAN")}</Header>
                            <ScannerEntry
                                inputValue={scannedCode}
                                inputPlaceholder={t("PAGE_LABEL-PAGE_ASSET_STOCK_PLACEHOLDER_SCAN")}
                                onCodeScanned={autoSubmit ? async (code) => {
                                    setScannedCode(code);
                                    await handleItemScan(code);
                                } : (code) => {
                                    setScannedCode(code);
                                }}
                                onChange={(code) => {
                                    setScannedCode(code);
                                }}
                                // scanContinuously={autoSubmit}
                                invalidSN={scannedCode ? !isSNValid(scannedCode) : false}
                            />


                        </Grid.Column>
                    </Grid.Row>


                    <Grid.Row verticalAlign="middle" columns={2}>
                        {!autoSubmit &&
                            <Grid.Column>
                                <Button
                                    fluid
                                    primary
                                    content={t("GENERIC_BUTTON-SUBMIT")}
                                    onClick={() => handleItemScan(scannedCode)}
                                />
                            </Grid.Column>}
                        {itemCount?.isSerialized &&
                            <Grid.Column>
                                <Button
                                    fluid
                                    primary
                                    content={t("PAGE_LABEL-PAGE_ASSET_STOCK_SHOW_REMAINING_ITEMS")}
                                    onClick={() => setOpen(true)}
                                />
                            </Grid.Column>}
                        <Grid.Column>
                            <Button
                                fluid
                                primary
                                content={t("GENERIC_BUTTON-CANCEL")}
                                onClick={() => {
                                    props.handleCancelEditMode();
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            )}
        </div>
    );
};
