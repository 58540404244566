import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { NavigationObserver, useNavigationContext } from "eyam-webui-components";
import { EYDAppView, EYDProcessOrderItem, ProcessOrderOperatedQuantity } from "eyd.models-ui";
import AppConfigService from "../../../../Services/AppConfigService";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import ProcessOrdersService from "../../../../Services/ProcessOrdersService";
import { DynamicAssetEdit } from "../../Assets/DynamicForm/DynamicAssetEdit";
import { DynamicProcessOrderEdit } from "./DynamicProcessOrderEdit";
import { DynamicProcessOrderScan } from "./DynamicProcessOrderScan";
import { DynamicItemEdit } from "../../Inventory/DynamicForm/DynamicItemEdit";


enum DynamicProcessOrderOperationStep {
    Scan = 1,
    EditAsset = 2,
    EditMaterial = 3,
    EditProcessOrder = 4,
}

const DynamicProcessOrderView: React.FunctionComponent<{}> = () => {
    const [currentStep, setCurrentStep] = useState(DynamicProcessOrderOperationStep.Scan)
    const [appView, setAppView] = useState<EYDAppView>(null);
    const [currentSNValue, setCurrentSNValue] = useState<string>(null);
    const [currentProcessOrderNumber, setCurrentProcessOrderNumber] = useState<string>(null);
    const [locationID, setLocationID] = useState<number>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [processOrderItemToUpdate, setProcessOrderItemToUpdate] = useState<EYDProcessOrderItem>(null);

    const { t } = useTranslation();
    const mounted = useRef(false);
    const navContext = useNavigationContext();
    const navigate = useNavigate();
    const rtParams = useParams<"id">();

    useEffect(() => {
        mounted.current = true;
        const load = async () => {
            try {
                setIsProcessing(true);
                let appV = await AppConfigService.getAppViewByID(rtParams.id);
                navContext.setTitle(t(appV?.localizedKey, appV?.name));
                let cFormViews = await AppConfigService.getFormViewsByAppViewID(appV.id);
                if (cFormViews?.length !== 2) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-FORM_ASSOCIATION_NUMBER"));
                    navigate(`/home`);
                }
                if (mounted.current) {
                    setAppView(appV);
                }
            } catch (e) {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_APP_VIEW_FAILED"), e);
                navigate(`/home`);
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        };
    }, [rtParams.id]);

    useEffect(() => {
        mounted.current = true;
        navContext.setTitle(t(appView?.localizedKey, appView?.name));
        navContext.navigationSubject.attach(() => { onBackButtonClick() });

        return () => {
            mounted.current = false;
            navContext.navigationSubject.detach();
        };
    }, [currentStep]);

    const onBackButtonClick: NavigationObserver = () => {
        if (currentStep === DynamicProcessOrderOperationStep.EditAsset) {
            setCurrentStep(DynamicProcessOrderOperationStep.EditProcessOrder);
        } else if (currentStep === DynamicProcessOrderOperationStep.EditMaterial) {
            setCurrentStep(DynamicProcessOrderOperationStep.EditProcessOrder);
        } else if (currentStep === DynamicProcessOrderOperationStep.EditProcessOrder) {
            setCurrentStep(DynamicProcessOrderOperationStep.Scan);
        } else if (currentStep === DynamicProcessOrderOperationStep.Scan) {
            navigate(-1);
            navContext.navigationSubject.detach();
        }
    }

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            console.error(details);
            EYDNotificationService.showError(errMsg, details);
        }
    }

    const handleAssetSaved = () => {

        try {
            setIsProcessing(true);
            ProcessOrdersService.updateProcessOrderItem(processOrderItemToUpdate);
            EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_ASSET_SUCCESS_ALERT_ASSET_SAVED"));
            setCurrentSNValue(null);
            setCurrentProcessOrderNumber(currentProcessOrderNumber);
            setCurrentStep(DynamicProcessOrderOperationStep.EditProcessOrder);

        } catch (e) {
            handleError(t("GENERIC_ERROR_ALERT-SUBMIT_FAILED"), e);
        }
        finally {
            setIsProcessing(false);
        }

    }

    const handleAssetScan = (processOrderItem, serialNumber: string) => {
        setProcessOrderItemToUpdate({ ...processOrderItem, serialNumber: serialNumber });
        setCurrentSNValue(serialNumber);
        setCurrentStep(DynamicProcessOrderOperationStep.EditAsset);
    }

    const handleMateriaSaved = (quantities: ProcessOrderOperatedQuantity[]) => {

        try {
            setIsProcessing(true);
            let usedQuantities = [...quantities];

            usedQuantities.forEach((value) => {
                value.processOrderItemID = processOrderItemToUpdate.id;
            });

            ProcessOrdersService.updateProcessOrderItem({ ...processOrderItemToUpdate, operatedQuantities: usedQuantities });
            EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_INVENTORY_SUCCESS_ALERT_ITEM_SAVE"));
            setCurrentSNValue(null);
            setCurrentProcessOrderNumber(currentProcessOrderNumber);
            setCurrentStep(DynamicProcessOrderOperationStep.EditProcessOrder);

        } catch (e) {
            handleError(t("GENERIC_ERROR_ALERT-SUBMIT_FAILED"), e);
        }
        finally {
            setIsProcessing(false);
        }

    }

    const handleMaterialSelect = (processOrderItem, sku: string) => {
        setProcessOrderItemToUpdate(processOrderItem);
        setCurrentSNValue(sku);
        setCurrentStep(DynamicProcessOrderOperationStep.EditMaterial);
    }

    const handleProcessOrderSaved = () => {
        setCurrentStep(DynamicProcessOrderOperationStep.Scan);
        setCurrentSNValue(null);
        setCurrentProcessOrderNumber(null);
        setLocationID(null);
        setIsProcessing(false);
        EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_PROCESS_ORDER_SUCCESS_ALERT_PROCESS_ORDER_SAVED"));
    }

    const processOrderSubmitted = (processOrderNumber, locationID) => {
        setCurrentProcessOrderNumber(processOrderNumber);
        setLocationID(locationID);
        setCurrentStep(DynamicProcessOrderOperationStep.EditProcessOrder)
    }


    const renderCurrentStep = (): JSX.Element => {

        switch (currentStep) {
            case DynamicProcessOrderOperationStep.Scan:
                return (
                    <DynamicProcessOrderScan
                        onProcessOrderSubmitted={processOrderSubmitted} />
                );

            case DynamicProcessOrderOperationStep.EditAsset:
                return (
                    <DynamicAssetEdit
                        currentAssetSN={currentSNValue}
                        appViewID={appView.id}
                        handleAssetSaved={handleAssetSaved}
                        loadingOverride={isProcessing}
                        handleAssetError={(msg) => { handleError(msg, ""); setCurrentStep(DynamicProcessOrderOperationStep.EditProcessOrder); }}
                    />
                );
            case DynamicProcessOrderOperationStep.EditMaterial:
                return (
                    <DynamicItemEdit
                        locationID={locationID}
                        currentItemSKU={currentSNValue}
                        handleItemSaved={handleMateriaSaved}
                        loadingOverride={isProcessing}
                        handleItemError={(msg) => { setCurrentStep(DynamicProcessOrderOperationStep.Scan); handleError(msg, ""); }}
                    />
                );
            case DynamicProcessOrderOperationStep.EditProcessOrder:
                return (
                    <DynamicProcessOrderEdit
                        locationId={locationID}
                        currentProcessOrderNumber={currentProcessOrderNumber}
                        handleProcessOrderSaved={() => { handleProcessOrderSaved(); }}
                        handleProcessOrderError={(msg, returnToScan) => { handleError(msg, ""); setCurrentStep(returnToScan ? DynamicProcessOrderOperationStep.Scan : DynamicProcessOrderOperationStep.EditProcessOrder); }}
                        handleAssetScan={(processOrderItem, sn) => handleAssetScan(processOrderItem, sn)}
                        handleMaterialSelect={(processOrderItem, sku) => handleMaterialSelect(processOrderItem, sku)}
                    />
                );
        }
    }

    return (
        <div className="dynamic-process-order-view">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (renderCurrentStep())}
        </div>
    );
};
export default DynamicProcessOrderView;