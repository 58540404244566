import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { NavigationObserver, useNavigationContext } from "eyam-webui-components";
import { EYDAppView, EYDFormView } from "eyd.models-ui";
import { DynamicAssetEdit } from "./DynamicAssetEdit";
import { DynamicAssetScan } from "./DynamicAssetScan";

import AppConfigService from "../../../../Services/AppConfigService";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import { DynamicEntityFieldValue, FormActionType } from "eyam-webui-models";
import { DynamicAssetScanType } from "./DynamicAssetScanType";
import { DynamicAssetFieldSelection } from "./DynamicAssetFieldSelection";


enum DynamicAssetOperationStep {
    ScanType = 1,
    FieldSelect = 2,
    Scan = 3,
    EditAsset = 4,
}

const DynamicAssetView: React.FunctionComponent<{}> = () => {
    const [currentStep, setCurrentStep] = useState(DynamicAssetOperationStep.Scan)
    const [currentSNValue, setCurrentSNValue] = useState<string>(null);
    const [appView, setAppView] = useState<EYDAppView>(null);
    const [formView, setFormView] = useState<EYDFormView>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [reuseEntityValues, setReusedEntityValues] = useState<Map<string, DynamicEntityFieldValue>>(new Map<string, DynamicEntityFieldValue>());

    const { t } = useTranslation();
    const mounted = useRef(false);
    const navContext = useNavigationContext();
    const navigate = useNavigate();
    const rtParams = useParams<"id">();

    useEffect(() => {
        mounted.current = true;
        const load = async () => {
            try {
                setIsProcessing(true);
                let appV = await AppConfigService.getAppViewByID(rtParams.id);
                navContext.setTitle(t(appV?.localizedKey, appV?.name));
                let cFormViews = await AppConfigService.getFormViewsByAppViewID(appV.id);
                if (cFormViews?.length === 0) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-NO_FORM_ASSOCIATION"));
                    navigate(`/home`);
                }
                if (mounted.current) {
                    if (appV.actions.some(a => a.actionType === FormActionType.AllowsSingleScan) && appV.actions.some(a => a.actionType === FormActionType.AllowsMultipleScans)) {
                        setCurrentStep(DynamicAssetOperationStep.ScanType);
                    } else if (appV.actions.some(a => a.actionType === FormActionType.AllowsMultipleScans)) {
                        setCurrentStep(DynamicAssetOperationStep.FieldSelect);
                    }
                    else {
                        setCurrentStep(DynamicAssetOperationStep.Scan);
                    }
                    setAppView(appV);
                    setFormView(cFormViews[0]);
                }
            } catch (e) {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_APP_VIEW_FAILED"), e);
                navigate(`/home`);
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        };
    }, [rtParams.id]);

    useEffect(() => {
        mounted.current = true;
        navContext.setTitle(t(appView?.localizedKey, appView?.name));
        navContext.navigationSubject.attach(() => { onBackButtonClick() });

        return () => {
            mounted.current = false;
            navContext.navigationSubject.detach();
        };
    }, [currentStep]);

    const onBackButtonClick: NavigationObserver = () => {
        if (currentStep === DynamicAssetOperationStep.EditAsset) {
            setCurrentStep(DynamicAssetOperationStep.Scan);
        } else if (currentStep === DynamicAssetOperationStep.Scan) {
            if (appView?.actions?.some(a => a.actionType === FormActionType.AllowsSingleScan) && appView?.actions?.some(a => a.actionType === FormActionType.AllowsMultipleScans)) {
                setCurrentStep(DynamicAssetOperationStep.ScanType);
            } else {
                navigate(-1);
                navContext.navigationSubject.detach();
            }
        } else if (currentStep === DynamicAssetOperationStep.FieldSelect) {
            setCurrentStep(DynamicAssetOperationStep.ScanType);
        } else if (currentStep === DynamicAssetOperationStep.ScanType) {
            navigate(-1);
            navContext.navigationSubject.detach();
        }
    }

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            console.error(details);
            EYDNotificationService.showError(errMsg, details);
        }
    }

    const handleReusedFieldsSaved = (fieldsMap: Map<string, DynamicEntityFieldValue>) => {
        setReusedEntityValues(fieldsMap);
        setCurrentStep(DynamicAssetOperationStep.Scan);
    }

    const handleAssetSaved = (currentEntityVals?: Map<string, DynamicEntityFieldValue>) => {
        setIsProcessing(false);
        if (currentEntityVals) {
            var newEntityFields = new Map<string, DynamicEntityFieldValue>(reuseEntityValues);
            reuseEntityValues.forEach((val, key) => {

                if (currentEntityVals.has(key)) {
                    newEntityFields.set(key, currentEntityVals.get(key));
                } else {
                    newEntityFields.set(key, new DynamicEntityFieldValue());
                }
            })
            setReusedEntityValues(newEntityFields);
        }

        EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_ASSET_SUCCESS_ALERT_ASSET_SAVED"));
        setCurrentStep(DynamicAssetOperationStep.Scan);
        setCurrentSNValue(null);
    }

    const handleAssetDeleted = () => {
        setIsProcessing(false);
        EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_ASSET_SUCCESS_ALERT_ASSET_DELETED"));
        setCurrentStep(DynamicAssetOperationStep.Scan);
        setCurrentSNValue(null);
    }

    const snSubmitted = (sn) => {
        setCurrentSNValue(sn);
        setCurrentStep(DynamicAssetOperationStep.EditAsset);
    }

    const scanTypeSelected = (reuseData: boolean) => {
        if (reuseData) {
            setCurrentStep(DynamicAssetOperationStep.FieldSelect);
        } else {
            setCurrentStep(DynamicAssetOperationStep.Scan);
        }
    }

    const renderCurrentStep = (): JSX.Element => {

        switch (currentStep) {
            case DynamicAssetOperationStep.ScanType:
                return (
                    <DynamicAssetScanType
                        onScanTypeSelected={scanTypeSelected} />
                );

            case DynamicAssetOperationStep.FieldSelect:
                return (
                    <DynamicAssetFieldSelection
                        formId={formView?.id}
                        loadingOverride={isProcessing}
                        handleReusedFieldsSaved={handleReusedFieldsSaved}
                    />
                );

            case DynamicAssetOperationStep.Scan:
                return (
                    <DynamicAssetScan
                        onSNSubmitted={snSubmitted} />
                );

            case DynamicAssetOperationStep.EditAsset:
                return (
                    <DynamicAssetEdit
                        currentAssetSN={currentSNValue}
                        appViewID={appView.id}
                        reuseEntityVals={reuseEntityValues}
                        handleAssetSaved={handleAssetSaved}
                        handleAssetDeleted={handleAssetDeleted}
                        loadingOverride={isProcessing}
                        handleAssetError={(msg) => { handleError(msg, ""); setCurrentStep(DynamicAssetOperationStep.Scan); }}
                    />
                );
        }
    }

    return (
        <div className="dynamic-asset-view">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (renderCurrentStep())}
        </div>
    );
};
export default DynamicAssetView;
