export const AppRoutes = {
    HOME: "/home",
    NOT_ALLOWED: "/notallowed",
    NOT_FOUND: "/notallowed",
    ASSET_FORM: "/asset-form/:id",
    MATERIAL_FORM: "/material-form/:id",
    PROCESS_ORDER_FORM: "/process-order-form/:id",
    ASSET_LIST: "/asset-list/:id",
    ITEM_COUNT_CONSUME: "/item-count-consume",
    ASSET_MAP: "/asset-map",
    SUPPORT: "/support",
    SETTINGS: "/settings"
};
