import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Icon, Button } from "semantic-ui-react";

interface DynamicAssetScanTypeProps {
    onScanTypeSelected: (reuseData: boolean) => void;
}

export const DynamicAssetScanType: React.FunctionComponent<DynamicAssetScanTypeProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="dynamic-asset-scan-view ">
            <Grid columns={1} stackable textAlign='center'>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                        <Header icon>
                            <Icon name='barcode' />
                            {t("PAGE_LABEL-PAGE_ASSET_LABEL_SELECT_SCAN_TYPE")}
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2} verticalAlign='middle' >
                    <Grid.Column>
                        <Button fluid primary
                            content={t("GENERIC_BUTTON-SINGLE_SCAN")}
                            onClick={() => { props.onScanTypeSelected(false); }}
                        />
                        <br />
                        <Button fluid primary
                            content={t("GENERIC_BUTTON-MULTI_SCAN")}
                            onClick={() => { props.onScanTypeSelected(true); }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    );
};
