import { EYDCustomFieldId } from "../../Config/CustomFieldId";
import { DynamicEntityBase, DynamicEntityFieldValue } from "eyam-webui-models";
export class EYDAsset extends DynamicEntityBase {
    get assetID() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.AssetID)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
    set assetID(astID) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueText = astID;
        this.setFieldValue(EYDCustomFieldId.AssetID, cVal);
    }
    get serialNumber() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.SerialNumber)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
    set serialNumber(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueText = value;
        this.setFieldValue(EYDCustomFieldId.SerialNumber, cVal);
    }
    get sku() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.SKU)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
    set sku(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueText = value;
        this.setFieldValue(EYDCustomFieldId.SKU, cVal);
    }
    get longitude() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.Longitude)) === null || _a === void 0 ? void 0 : _a.valueDouble;
    }
    set longitude(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueDouble = value;
        this.setFieldValue(EYDCustomFieldId.Longitude, cVal);
    }
    get latitude() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.Latitude)) === null || _a === void 0 ? void 0 : _a.valueDouble;
    }
    set latitude(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueDouble = value;
        this.setFieldValue(EYDCustomFieldId.Latitude, cVal);
    }
    get locationID() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.LocationID)) === null || _a === void 0 ? void 0 : _a.valueLong;
    }
    set locationID(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueLong = value;
        this.setFieldValue(EYDCustomFieldId.LocationID, cVal);
    }
    get lastModifiedDate() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.LastModifiedDate)) === null || _a === void 0 ? void 0 : _a.valueDateTime;
    }
    get lastModifiedBy() {
        var _a;
        return (_a = this.getFieldValue(EYDCustomFieldId.LastModifiedBy)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
}
