import { useTranslation } from "react-i18next";
import { BusinessLocation, EYDItemCount, EYDItemCountSession } from "eyd.models-ui";
import React, { useEffect, useRef, useState } from "react";
import { Grid, Header, Icon, Button, Select, Loader, Form } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import BusinessLocationsService from "../../../../Services/BusinessLocationsService";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import ItemCountService from "../../../../Services/ItemCountService";
import moment from "moment";
import GlobalSettingsService from "../../../../Services/GlobalSettingsService";
import { BaseGlobalSettings } from "eyam-webui-models";
import { formatDate } from "eyam-webui-components";

interface ConsumeItemCountScanProps {
    onSubmit: (itemCountID: number) => void;
}

export const ConsumeItemCountScan: React.FunctionComponent<ConsumeItemCountScanProps> = (props) => {

    const [locationID, setLocationID] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date>(new Date(moment().startOf("month").startOf("day").format()));
    const [endDate, setEndDate] = useState<Date>(new Date(moment().endOf("day").format()));
    const [sessionID, setSessionID] = useState<number>();
    const [itemCountID, setItemCountID] = useState<number>();

    const [locations, setLocations] = useState<BusinessLocation[]>([]);
    const [itemCountSessions, setItemCountSessions] = useState<EYDItemCountSession[]>([]);
    const [itemCounts, setItemCounts] = useState<EYDItemCount[]>([]);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [dateFormat, setDateFormat] = useState<string>("DD-MM-YYYY");
    const mounted = useRef(false);
    const { t } = useTranslation();

    useEffect(() => {
        mounted.current = true;

        const load = async () => {
            try {
                if (sessionID != null && sessionID != 0) {

                    setIsProcessing(true);
                    const session = await ItemCountService.getItemCountSession(sessionID, true);
                    if (mounted.current) {
                        setItemCounts(session.itemCounts);
                    }
                }
            } catch (e) {
                if (mounted.current) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_ITEM_COUNTS_FAILED"), e);
                }
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        };
    }, [sessionID]);

    useEffect(() => {
        mounted.current = true;

        const load = async () => {
            try {
                setIsProcessing(true);
                const locations = await BusinessLocationsService.getAllBusinessLocations();
                const sessions = await ItemCountService.getItemCountSessions(0);
                let setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.DateFormat);

                if (mounted.current) {
                    setLocationID(locations[0]?.id);
                    setLocations(locations);
                    setItemCountSessions(sessions);
                    if (setting.settingId != "00000000-0000-0000-0000-000000000000") {
                        setDateFormat(setting.settingValue.valueText);
                    }
                }
            } catch (e) {
                if (mounted.current) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_DATA_FAILED"), e);
                }
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        };
    }, []);

    const isSessionInDateRange = (session: EYDItemCountSession) => {

        var sessionDate = new Date(session.dateCreated);
        if (startDate != null && sessionDate < startDate) {
            return false;
        }
        if (endDate != null && sessionDate > endDate) {
            return false;
        }
        return true;
    }


    return (
        <div className="info-scan-view ">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable textAlign="center">
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column>
                            <Form className="form-fields">
                                <Header icon>
                                    <Icon name="barcode" />
                                    {t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_LOCATIONS")}
                                </Header>
                                <Form.Field
                                    control={Select}
                                    search={true}
                                    options={locations.map((l) => ({ key: l.id, value: l.id, text: l.name }))}
                                    onChange={(e, data) => setLocationID(data.value as number)}
                                    value={locationID}
                                />
                                <Header>{t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_DATE_RANGE")}</Header>
                                <Form.Field
                                    label={t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_START_DATE")}
                                    control={DateInput}
                                    inline={false}
                                    dateFormat={dateFormat}
                                    closable={true}
                                    onChange={(e, data) => { var date: Date = moment(data.value, dateFormat, true).isValid() ? new Date(moment(data.value, dateFormat).startOf("day").format()) : null; setStartDate(date); }}
                                    value={startDate ? moment(startDate).format(dateFormat) : ""}
                                />
                                <Form.Field
                                    label={t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_END_DATE")}
                                    control={DateInput}
                                    inline={false}
                                    dateFormat={dateFormat}
                                    closable={true}
                                    onChange={(e, data) => { var date: Date = moment(data.value, dateFormat, true).isValid() ? new Date(moment(data.value, dateFormat).endOf("day").format()) : null; setEndDate(date); }}
                                    value={endDate ? moment(endDate).format(dateFormat) : ""}
                                />

                                <Header>{t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_ITEM_COUNT_SESSIONS")}</Header>
                                <Form.Field
                                    control={Select}
                                    search={true}
                                    options={itemCountSessions.filter(s => (locationID == 0 || s.businessLocationID == locationID) && isSessionInDateRange(s))
                                        .map((s) => ({ key: s.id, value: s.id, text: `${s.publicId} | ${s.countName} (${formatDate(s.dateCreated, dateFormat)})` }))}
                                    onChange={(e, data) => { setSessionID(data.value as number); setItemCountID(null); }}
                                    value={sessionID}
                                />
                                <Header>{t("PAGE_LABEL-PAGE_ASSET_STOCK_LABEL_ITEM_COUNTS")}</Header>
                                <Form.Field
                                    control={Select}
                                    search={true}
                                    options={itemCounts?.map((s) => ({ key: s.id, value: s.id, text: `${s.publicId} | ${s.sku} (${formatDate(s.dateCreated, dateFormat + " HH:mm")})` }))}
                                    onChange={(e, data) => setItemCountID(data.value as number)}
                                    value={itemCountID}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} verticalAlign='middle' >
                        <Grid.Column>
                            <Button
                                fluid
                                primary
                                content={t("GENERIC_BUTTON-SCAN_ITEMS")}
                                onClick={() => {
                                    if (itemCountID != null) {
                                        props.onSubmit(itemCountID);
                                    }
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </div>
    );
};
