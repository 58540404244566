import { EYDProcessOrder, EYDProcessOrderItem } from "eyd.models-ui";
import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";

class ProcessOrdersService extends BaseService {
    private readonly BASE_URL: string = "/api/ProcessOrders";

    getProcessOrderByNumber(processOrderNumber: string) : Promise<EYDProcessOrder> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetProcessOrderByNumber/${processOrderNumber}`)
                .then((response) => {
                    resolve(response.data.value);
                })
                .catch((error) => this.handleError(error, reject));
        })
    }

    getProcessOrderWithItems(processOrderNumber: string) : Promise<{processOrder: EYDProcessOrder, processOrderItems: EYDProcessOrderItem[]}> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetProcessOrderWithItems/${processOrderNumber}`)
                .then((response) => {
                    resolve(response.data.value);
                })
                .catch((error) => this.handleError(error, reject));
        })
    }

    updateProcessOrderItem(processOrderItem: EYDProcessOrderItem) : Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/UpdateProcessOrderItem`, processOrderItem)
                .then((response) => {
                    resolve(response.data.value);
                }
                ).catch((error) => this.handleError(error, reject));
        })
    }

    updateProcessOrder(processOrder: EYDProcessOrder) : Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/UpdateProcessOrder`, processOrder)
                .then((response) => {
                    resolve(response.data.value);
                }
                ).catch((error) => this.handleError(error, reject));
        })
    }


}

export default new ProcessOrdersService();