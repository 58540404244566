import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { EYDCustomField  } from "eyd.models-ui";
import { FieldTargetType } from "eyam-webui-models";
import { EYAMApiResult } from "eyam-webui-models";

class CustomFieldsService extends BaseService {
    private readonly BASE_URL: string = "/api/CustomFields";


    getAllCustomFields(includeIntrinsic: boolean): Promise<EYDCustomField[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDCustomField[]>>(`${this.BASE_URL}/GetAllCustomFields/${includeIntrinsic}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getCustomFieldsByType(targetType: FieldTargetType): Promise<EYDCustomField[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDCustomField[]>>(`${this.BASE_URL}/GetCustomFieldsByType/${targetType}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getCustomFieldById(id: string): Promise<EYDCustomField> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDCustomField>>(`${this.BASE_URL}/GetCustomFieldById/${id}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    queryCustomFields(cfQueryData: {
        includeIDs: string[] ,
        targetType: FieldTargetType
    }): Promise<EYDCustomField[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<EYDCustomField[]>>(`${this.BASE_URL}/QueryCustomFields/`, cfQueryData)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}

export default new CustomFieldsService();