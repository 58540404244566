import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Icon, Button, Loader } from "semantic-ui-react";
import { ScannerEntry } from "../../../Controls/ScannerEntry";

interface DynamicAssetScanProps {
    onSNSubmitted: (sn: string) => void;
}

export const DynamicAssetScan: React.FunctionComponent<DynamicAssetScanProps> = (props) => {
    const [scannedSN, setScannedSN] = useState<string>(null);
    const { t } = useTranslation();

    const isSNValid = (sn: string): boolean => {
        return sn.length > 0;
    }

    return (
        <div className="dynamic-asset-scan-view">
            <Grid columns={1} stackable textAlign='center'>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                        <Header icon>
                            <Icon name='barcode' />
                            {t("PAGE_LABEL-PAGE_ASSET_LABEL_SCAN_ASSET")}
                        </Header>
                        <ScannerEntry
                            inputValue={scannedSN}
                            inputPlaceholder={t("PAGE_LABEL-PAGE_ASSET_PLACEHOLDER_SCAN_ASSET")}
                            onCodeScanned={(code) => {
                                setScannedSN(code);
                            }}
                            invalidSN={scannedSN ? !isSNValid(scannedSN) : false}
                        />

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2} verticalAlign='middle' >
                    <Grid.Column>
                        <Button fluid primary
                            content={t("GENERIC_BUTTON-SCAN_ASSET")}
                            onClick={() => { if (scannedSN && isSNValid(scannedSN)) { props.onSNSubmitted(scannedSN); } }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};
