import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Loader, Grid, Header, Form, Button, Icon, Checkbox } from "semantic-ui-react";
import { DynamicEntityFieldValue, FieldTargetType } from "eyam-webui-models";
import { EYDCustomFieldId, EYDFormViewCustomFieldAssociation, EYDCustomField } from "eyd.models-ui";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import AppConfigService from "../../../../Services/AppConfigService";
import CustomFieldsService from "../../../../Services/CustomFieldsService";

interface DynamicAssetFieldSelectionProps {
    formId: string;
    loadingOverride: boolean;
    handleReusedFieldsSaved: (fieldsMap: Map<string, DynamicEntityFieldValue>) => void;
}

export const DynamicAssetFieldSelection: React.FunctionComponent<DynamicAssetFieldSelectionProps> = (props) => {

    const [reuseEntityFields, setReusedEntityFields] = useState<Map<string, DynamicEntityFieldValue>>(new Map<string, DynamicEntityFieldValue>());
    const [fieldAssociations, setFieldAssociations] = useState<EYDFormViewCustomFieldAssociation[]>([]);
    const [customFields, setCustomFields] = useState<EYDCustomField[]>([]);

    const [isConfigLoading, setIsConfigLoading] = useState<boolean>(false);

    const { t } = useTranslation();
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        setIsConfigLoading(true);

        let load = async () => {
            try {
                let asocs = await AppConfigService.getFormViewCustomFieldAssociationByFormViewID(props.formId);
                asocs = asocs.filter(a => a.customFieldID !== EYDCustomFieldId.Longitude && a.customFieldID !== EYDCustomFieldId.AssetID &&
                    a.customFieldID !== EYDCustomFieldId.LastModifiedBy && a.customFieldID !== EYDCustomFieldId.LastModifiedDate &&
                    a.customFieldID !== EYDCustomFieldId.SerialNumber).sort((a, b) => a.displayOrder - b.displayOrder);

                let fieldIdList = asocs.filter(a => a.customFieldID !== EYDCustomFieldId.Latitude).map(a => a.customFieldID);
                let currentFields = await CustomFieldsService.queryCustomFields({
                    includeIDs: fieldIdList,
                    targetType: FieldTargetType.Asset
                });

                var gpsCF = new EYDCustomField();
                gpsCF.customFieldID = EYDCustomFieldId.Latitude;
                gpsCF.customFieldName = "GPS Coordinates";
                gpsCF.customFieldLocalizedKey = "CF_NAME-GPS_COORDINATES";

                currentFields.push(gpsCF)

                if (mounted.current) {
                    setFieldAssociations(asocs);
                    setCustomFields(currentFields);
                }
            } catch (e) {
                handleError(t("GENERIC_ERROR_ALERT-LOADING_CONFIGURATION_FAILED"), e);
            } finally {
                setIsConfigLoading(false);
            }
        }

        load();

        return () => {
            mounted.current = false;
        }

    }, [])

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            console.error(details);
            EYDNotificationService.showError(errMsg, details);
        }
    }

    const handleFieldChecked = (fieldId, checked) => {
        var newEntityFields = new Map<string, DynamicEntityFieldValue>(reuseEntityFields);
        if (checked) {
            newEntityFields.set(fieldId, new DynamicEntityFieldValue());
            if (fieldId === EYDCustomFieldId.Latitude) {
                newEntityFields.set(EYDCustomFieldId.Longitude, new DynamicEntityFieldValue());
            }
            setReusedEntityFields(newEntityFields);
        } else {
            newEntityFields.delete(fieldId);
            if (fieldId === EYDCustomFieldId.Latitude) {
                newEntityFields.delete(EYDCustomFieldId.Longitude);
            }
            setReusedEntityFields(newEntityFields);
        }
    }

    return (
        <div className="dynamic-asset-scan-view">

            {props.loadingOverride || isConfigLoading ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Header icon className="common-view__view-header-logo">
                                <Icon name='tasks' />
                                {t("PAGE_LABEL-PAGE_ASSET_LABEL_SELECT_REUSE_FIELDS")}
                            </Header>
                            <Form>
                                {fieldAssociations.map((value, index) => {
                                    return (
                                        <Form.Field key={'field_' + index}>
                                            <Checkbox
                                                label={t(customFields.find(cf => cf.customFieldID === value.customFieldID)?.customFieldLocalizedKey, customFields.find(cf => cf.customFieldID === value.customFieldID)?.customFieldName)}
                                                onChange={(e, { checked }) => { handleFieldChecked(value.customFieldID, checked); }}
                                            />
                                        </Form.Field>
                                    )
                                })}
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Button fluid primary
                                content={t("GENERIC_BUTTON-SUBMIT")}
                                onClick={() => { props.handleReusedFieldsSaved(reuseEntityFields); }}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            )}
        </div>
    );
};