import React from 'react';
import { useTranslation } from "react-i18next";

const NoMatchPage: React.FunctionComponent<{}> = (props) => {
    const { t } = useTranslation();
    
    return (
        <div>
            <h3>{t("GENERIC_ERROR_ALERT-NOT_FOUND")}</h3>
        </div>
    );
}

export default NoMatchPage