import React, { useEffect, useState, useRef } from "react";
import { Header, Icon, Button, Modal, Dropdown, Input, Label } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

import { BrowserMultiFormatReader, VideoInputDevice } from "@zxing/library";

import "../../../styles/shared/scanner-view.scss";
import { DropdownItemProps } from "semantic-ui-react";

interface ScannerEntryProps {
    inputValue: string;
    inputPlaceholder: string;
    onCodeScanned: (code: string) => void;
    onChange?: (code: string) => void;
    invalidSN: boolean;
    errorMsg?: string;
}


export const ZXingScannerWrapper: React.FunctionComponent<{ setVideoLoading: (loadstate: boolean) => void, onCodeScanned: (code: string) => void/*, scanContinuously?: boolean */}> = (props) => {
    const [zxingReader, setZXingReader] = useState<BrowserMultiFormatReader>(null);
    const readerRef = useRef(zxingReader);
    const { t } = useTranslation();
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>("DEFAULT");
    const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
    let videoRef = useRef<HTMLVideoElement>(null);


    useEffect(() => {
        let zr = new BrowserMultiFormatReader();
        zr.listVideoInputDevices().then((videoInputDevices) => {
            if (videoInputDevices.length >= 0) {
                zr.reset();
                setZXingReader(zr);
                setVideoInputDevices(videoInputDevices);
            }
        });

        return () => {
            try {

                console.debug("ZX: cleanup starting");

                if (zxingReader) {
                    console.debug("ZX: cleanup stop decode");
                    zxingReader.stopAsyncDecode();
                    zxingReader.stopContinuousDecode();
                }

                if (videoRef?.current) {
                    console.debug("ZX: cleanup Disposing camera");
                    disposeCamera(videoRef?.current);
                }
            } catch (err) {
                console.debug("ZX: cleanup error");
                console.error(err);
            }
        };
    }, []);
    
    useEffect(() => {
        if (zxingReader) {
            console.debug("ZX: WRAPPER SEL OR ZXING CHANGE EFFECT");
            zxingReader.reset();
            let vidElm = document.getElementById("video_scanner_qr");

            if (vidElm) {
                zxingReader
                    .decodeOnceFromVideoDevice(selectedDeviceId == "DEFAULT" ? undefined : selectedDeviceId, "video_scanner_qr")
                    .then((result) => {
                        console.debug(result);
                        var scannedText = result.getText();
                        zxingReader.stopAsyncDecode();
                        zxingReader.stopContinuousDecode();
                        disposeCamera(videoRef?.current)
                        props.onCodeScanned(scannedText);
                    })
                    .catch((err) => {
                        console.debug(err);
                    });
            }
        }
    }, [selectedDeviceId, zxingReader]);

    const getCameraOptions = (): DropdownItemProps[] => {

        let camOptions = [{
            key: 0,
            text: "Default",
            value: "DEFAULT"
        }];

        let foundVideos = videoInputDevices.map((element, idx) => {
            return {
                key: idx + 1,
                text: element.label,
                value: element.deviceId
            };
        });

        camOptions = camOptions.concat(foundVideos);

        return camOptions;
    }

    const handleCameraChange = (cameraId: string): void => {
        setSelectedDeviceId(cameraId);

    };

    const disposeCamera = (videoElement: any) => {
        if (videoElement) {
            let tracks = videoElement.srcObject.getVideoTracks();

            if (tracks) {
                console.debug("ZX: stopping tracks");
                tracks.forEach((track: any) => track.stop());
                return;
            }
        }
    };

    return (<>
        <video
            ref={videoRef}
            className="scanner-modal-content__video"
            id="video_scanner_qr"
           onLoadStart={() => {
                console.debug("ZX: Video starting");
                props.setVideoLoading(true);
            }}
            onLoadedData={() => {
                console.debug("ZX: Video loaded");
                props.setVideoLoading(false);
            }}
            width="300"
            height="200"
        />
        <Dropdown
            className="scanner-modal-content__dropdown"
            placeholder={t("GENERIC-LABEL-CAMERA")}
            onChange={(_, data) => {
                handleCameraChange(data.value as string);
            }}
            value={selectedDeviceId}
            selection
            options={getCameraOptions()}
        /></>
    )
}

export const ScannerEntry: React.FunctionComponent<ScannerEntryProps> = (props) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [videoLoading, setVideoLoading] = React.useState(true);

    const { t } = useTranslation();

    return (
        <div className="scanner-view">
            <div className="scanner-view__scan-entry">
                <Input
                    placeholder={props.inputPlaceholder ? props.inputPlaceholder : t("GENERIC-LABEL-SCAN_QR")}
                    value={props.inputValue ? props.inputValue : ""}
                    onChange={(_, d) => { props.onChange ? props.onChange(d.value) : props.onCodeScanned(d.value); }}
                    fluid
                    error={props.invalidSN}
                />
            </div>

            <div className="scanner-view__scan-btn">
                <Modal basic
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    onOpen={() => {
                        setModalOpen(true);
                    }}
                    open={modalOpen}
                    trigger={<Icon name="qrcode" size="big" />}
                    size="small">

                    <Header>{videoLoading ? t("GENERIC-LOADING_ALERT") : (props.inputPlaceholder ? props.inputPlaceholder : t("GENERIC-LABEL-SCAN_QR"))}</Header>
                    <Modal.Content>
                        <div className="scanner-modal-content">
                            <ZXingScannerWrapper
                                setVideoLoading={(val) => { setVideoLoading(val); }}
                                onCodeScanned={(code) => { props.onCodeScanned(code); setModalOpen(false); }}
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            color="red"
                            inverted
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            <Icon name="remove" /> {t("GENERIC_BUTTON-CANCEL")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
            {props.errorMsg && props.invalidSN == true && <Label basic color='red' pointing content={props.errorMsg} />}
        </div>
    );
};


