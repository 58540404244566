import { DynamicForm } from "eyam-webui-components";
import { BusinessLocation } from "eyd.models-ui";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Icon, Button, Select, Loader } from "semantic-ui-react";
import BusinessLocationsService from "../../../../Services/BusinessLocationsService";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import { ScannerEntry } from "../../../Controls/ScannerEntry";

interface DynamicProcessOrderScanProps {
    onProcessOrderSubmitted: (processOrder: string, locationID: number) => void;
}

export const DynamicProcessOrderScan: React.FunctionComponent<DynamicProcessOrderScanProps> = (props) => {
    const [scannedNumber, setScannedNumber] = useState<string>(null);
    const [locationID, setLocationID] = useState<number>();
    const [locations, setLocations] = useState<BusinessLocation[]>([]);
    const { t } = useTranslation();
    const mounted = useRef(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        mounted.current = true;

        const load = async () => {
            try {
                setIsProcessing(true);
                const locations = await BusinessLocationsService.getAllBusinessLocations();
                if (mounted.current) {
                    setLocationID(locations[0]?.id);
                    setLocations(locations);
                }
            } catch (e) {
                if (mounted.current) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_LOCATIONS_FAILED"), e);
                }
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        }
    }, []);


    const handleLocationChange = (locationID: number) => {
        setLocationID(locationID);
    };

    // THIS SHOULD BE REPLACED WITH A REAL VALIDATOR ONCE WE KNOW HOW A PROCESS ORDER NUMBER LOOKS LIKE
    const isPRocessOrderNumberValid = (processOrderNUmber: string): boolean => {
        if (!processOrderNUmber) {
            return false;
        }
        return true;
    }


    return (
        <div className="data-collect-scan-view ">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable textAlign='center'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Header icon>
                                <Icon name='barcode' />
                                {t("PAGE_LABEL-PAGE_PROCESS_ORDER_LABEL_SCAN")}
                            </Header>
                            <ScannerEntry
                                inputValue={scannedNumber}
                                inputPlaceholder={t("PAGE_LABEL-PAGE_PROCESS_ORDER_PLACEHOLDER_SCAN")}
                                onCodeScanned={(code) => {
                                    setScannedNumber(code);
                                }}
                                invalidSN={scannedNumber ? !isPRocessOrderNumberValid(scannedNumber) : false}
                            />
                            <Header>{t("PAGE_LABEL-PAGE_INVENTORY_LABEL_LOCATIONS")}</Header>
                            <DynamicForm
                                groups={[
                                    {
                                        tabPane: { content: "Tab 1" },
                                        formFields: [
                                            {
                                                placeholder: t("PAGE_LABEL-PAGE_INVENTORY_PLACEHOLDER_LOCATIONS"),
                                                control: Select,
                                                search: true,
                                                options: locations.map((l) => ({ key: l.id, value: l.id, text: l.name })),
                                                onChange: (_, { value }) => handleLocationChange(value),
                                                defaultValue: locationID
                                            }
                                        ]
                                    }
                                ]}
                            />

                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} verticalAlign='middle' >
                        <Grid.Column>
                            <Button fluid primary
                                content={t("GENERIC_BUTTON-SCAN_PROCESS_ORDER")}
                                onClick={() => { if (isPRocessOrderNumberValid(scannedNumber)) { props.onProcessOrderSubmitted(scannedNumber, locationID); } }}
                            />
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            )}
        </div>
    );
};
