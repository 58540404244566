import { EYAMApiResult } from "eyam-webui-models";
import { GlobalSetting } from "eyd.models-ui";
import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";

class GlobalSettingsService extends BaseService {
    private readonly BASE_URL: string = "/api/GlobalSettings";

    getGlobalSettingById(id: string): Promise<GlobalSetting> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<GlobalSetting>>(`${this.BASE_URL}/GetGlobalSettingById/${id}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new GlobalSettingsService();