import {
    DynamicEntityFieldType,
    DynamicEntityFieldValue,
    IntrinsicAssetCustomFieldID,
    IntrinsicInventoryItemCustomFieldID
} from "eyam-webui-models";
import { EYDCustomField, EYDFormViewCustomFieldAssociation, EYDLookupTableValue } from "eyd.models-ui";
import ItemMasterDetailService from "../Services/ItemMasterDetailService";

const customFieldIDs = [IntrinsicAssetCustomFieldID.SKU, IntrinsicInventoryItemCustomFieldID.SKU];
const findFieldIdPredicate = (value) => customFieldIDs.indexOf(value) !== -1;

const loadSKUFieldLookupData = async (customFieldID: string) => {
    const lookupData = new Map<string, EYDLookupTableValue>();
    let itSkuNames = await ItemMasterDetailService.getIMDSkuBySerialization(true);

    for (let it of itSkuNames) {
        const lookupTableVal: EYDLookupTableValue = {
            ...new EYDLookupTableValue(),
            id: it.sku,
            name: it.sku + " | " + it.displayName,
            localizedKey: it.sku + " | " + it.displayName,
            lookupTableID: customFieldID
        };
        lookupData.set(lookupTableVal.id, lookupTableVal);
    }
    return lookupData;
};

const mergeSKUsDataInConfig = async (config: {
    customFieldMap: Map<string, EYDCustomField>;
    fieldAssociations: EYDFormViewCustomFieldAssociation[];
    lookupValuesMap: Map<string, Map<string, EYDLookupTableValue>>;
    usedFieldsList: string[];
}) => {
    const customFieldSKU = config.usedFieldsList.find(findFieldIdPredicate);
    if (!customFieldSKU) {
        return config;
    }
    const lookupData = await loadSKUFieldLookupData(customFieldSKU);
    const customField = config.customFieldMap.get(customFieldSKU);
    applyLookupCustomfield(customField);
    config.lookupValuesMap.set(customFieldSKU, lookupData);
};

const applyLookupCustomfield = (customField: EYDCustomField) => {
    customField.customFieldName = "SKU";
    customField.fieldType = DynamicEntityFieldType.Lookup;
    customField.lookupTableID = customField.customFieldID;
};

const applyLookupSKU = (fieldValues: Map<string, DynamicEntityFieldValue>, fieldAssociations: EYDFormViewCustomFieldAssociation[]) => {
    const fieldValue = new DynamicEntityFieldValue();
    const fieldAssociation = fieldAssociations.find((value) => findFieldIdPredicate(value.customFieldID));
    if (!fieldAssociation) {
        return;
    }
    const value = fieldAssociation.overrideWithDefaultValue
        ? fieldAssociation.defaultValue
        : fieldValues.get(fieldAssociation.customFieldID)?.getObjectValue(DynamicEntityFieldType.String);
    if (value === null || value === undefined) {
        fieldValue.setObjectValue(null, DynamicEntityFieldType.Lookup);
    } else {
        fieldValue.setObjectValue(`${value}`, DynamicEntityFieldType.Lookup);
    }
    fieldValues.set(fieldAssociation.customFieldID, fieldValue);
};

const applySKU = (fieldValues: Map<string, DynamicEntityFieldValue>, fieldAssociations: EYDFormViewCustomFieldAssociation[]) => {
    const newFieldValue = new DynamicEntityFieldValue();
    const fieldAssociation = fieldAssociations.find((value) => findFieldIdPredicate(value.customFieldID));
    if (!fieldAssociation) {
        return;
    }
    let fieldValue = fieldValues.get(fieldAssociation.customFieldID);
    newFieldValue.setObjectValue(fieldValue.getObjectValue(DynamicEntityFieldType.Lookup), DynamicEntityFieldType.String);
    fieldValues.set(fieldAssociation.customFieldID, newFieldValue);
};

export { mergeSKUsDataInConfig, applyLookupSKU, applySKU };
