import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { EYDAsset, EYDCustomFieldId, EYDFieldValue } from "eyd.models-ui";

import { DynamicEntityQueryFilter, DynamicEntityQueryFilterParameters, DynamicEntityQueryFilterType, ListLogicalOperation, ListLogicalOperationType } from "eyam-webui-models";

class EYDAssetDTO {
    public assetID: string;
    public fieldValues: Map<string, EYDFieldValue>;
    public lastModifiedBy: string;
    public lastModifiedDate: string;
    public latitude: number;
    public locationID: number;
    public longitude: number;
    public serialNumber: number;
}

const mapListOperationTypeToQueryFilterType = (type: ListLogicalOperationType) => {
    switch (type) {
        case ListLogicalOperationType.AND: return DynamicEntityQueryFilterType.AND;
        case ListLogicalOperationType.OR: return DynamicEntityQueryFilterType.OR;
        case ListLogicalOperationType.LIKE: return DynamicEntityQueryFilterType.LIKE;
        case ListLogicalOperationType.EQ: return DynamicEntityQueryFilterType.EQ;
        case ListLogicalOperationType.GT: return DynamicEntityQueryFilterType.GT;
        case ListLogicalOperationType.LT: return DynamicEntityQueryFilterType.LT;
        case ListLogicalOperationType.GTE: return DynamicEntityQueryFilterType.GTE;
        case ListLogicalOperationType.LTE: return DynamicEntityQueryFilterType.LTE;
        case ListLogicalOperationType.IN: return DynamicEntityQueryFilterType.IN;
    }
}

const mapListOperationToQueryFilter = (operation: ListLogicalOperation) => {
    if (operation) {
        let filter = new DynamicEntityQueryFilter();
        filter.operandID = operation.operandID
        filter.operandValue = operation.operandValue
        filter.filterType = mapListOperationTypeToQueryFilterType(operation.filterType);

        if (operation.operands?.length > 0) {
            filter.operands = [];

            for (let operand of operation.operands) {
                filter.operands.push(mapListOperationToQueryFilter(operand))
            }
        }
        return filter;
    }
    return null;
}



class AssetsService extends BaseService {
    private readonly BASE_URL: string = "/api/Assets";

    getAssetBySerial(serial: string, fieldList: string[]): Promise<EYDAsset> {
        let qryParams = new DynamicEntityQueryFilterParameters();

        qryParams.fields = fieldList;
        qryParams.filter = new DynamicEntityQueryFilter();
        qryParams.filter.filterType = DynamicEntityQueryFilterType.EQ;
        qryParams.filter.operandID = EYDCustomFieldId.SerialNumber;
        qryParams.filter.operandValue = serial;

        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryAssets`, qryParams)
                .then((response) => {
                    let astValues = response.data.value as EYDAssetDTO[];

                    if (astValues && astValues[0]) {
                        let cVal = new EYDAsset();


                        for (var k in astValues[0].fieldValues) {
                            let fieldValue = Object.assign(new EYDFieldValue(), astValues[0].fieldValues[k]);
                            cVal.setFieldValue(k, fieldValue);
                        }
                        cVal.assetID = astValues[0].assetID;

                        resolve(cVal);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    queryAssets(fieldList: string[] = []): Promise<EYDAsset[]> {
        let qryParams = new DynamicEntityQueryFilterParameters();
        qryParams.fields = fieldList;
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryAssets`, qryParams)
                .then((response) => {
                    let assetValues = response.data.value as EYDAssetDTO[];
                    let result: EYDAsset[] = null;
                    if (assetValues) {
                        result = [];
                        for (let asset of assetValues) {
                            let cVal = new EYDAsset();

                            for (var k in asset.fieldValues) {
                                let fieldValue = Object.assign(new EYDFieldValue(), asset.fieldValues[k]);
                                cVal.setFieldValue(k, fieldValue);
                            }
                            cVal.assetID = asset.assetID;
                            result.push(cVal);
                        }
                    }
                    resolve(result);
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    queryAssetsCount(qryfilter: ListLogicalOperation): Promise<number> {
        let qryParams = new DynamicEntityQueryFilterParameters();
        qryParams.filter = mapListOperationToQueryFilter(qryfilter);

        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryAssetsCount`, qryParams)
                .then((response) => {
                    let itemCount = response.data.value as number;

                    resolve(itemCount);
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    queryAssetsWithDetails(fieldList: string[] = [], orderByFields: string[] = [], sortAscending: boolean, pagSize: number, pageNumber: number, qryfilter: ListLogicalOperation): Promise<EYDAsset[]> {
        let qryParams = new DynamicEntityQueryFilterParameters();
        qryParams.fields = fieldList;
        qryParams.orderByFields = orderByFields;
        qryParams.sortAsceding = sortAscending;
        qryParams.pageSize = pagSize;
        qryParams.pageNumber = pageNumber;
        qryParams.filter = mapListOperationToQueryFilter(qryfilter);


        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryAssets`, qryParams)
                .then((response) => {
                    let assetValues = response.data.value as EYDAssetDTO[];
                    let result: EYDAsset[] = null;

                    if (assetValues) {
                        result = [];
                        for (let asset of assetValues) {
                            let cVal = new EYDAsset();
                            cVal.fieldValues = new Map<string, EYDFieldValue>();

                            for (var k in asset.fieldValues) {
                                let fieldValue = Object.assign(new EYDFieldValue(), asset.fieldValues[k]);
                                cVal.fieldValues.set(k, fieldValue);
                            }
                            cVal.assetID = asset.assetID;
                            result.push(cVal);
                        }
                    }
                    resolve(result);
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertAssetData(data: EYDAsset): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/upsertAssetData`, {
                assetID: data.assetID,
                fieldValues: data.getFieldValuesMapped()
            })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    deleteAsset(assetId: string): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<any>(`${this.BASE_URL}/DeleteAsset`, { params: { assetId } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

}
export default new AssetsService();
