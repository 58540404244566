import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { EYDInventoryItem, EYDItemFieldValue, EYDQuantityFieldValue } from "eyd.models-ui";

import {
    DynamicEntityQueryFilter,
    DynamicEntityQueryFilterParameters,
    DynamicEntityQueryFilterType,
    IntrinsicInventoryItemCustomFieldID
} from "eyam-webui-models";

class EYDInventoryItemDTO {
    public itemId: number;
    public fieldValues: Map<string, EYDItemFieldValue>;
    public quantityValues: Map<string, EYDQuantityFieldValue[]>;
    public lastModifiedBy: string;
    public lastModifiedDate: string;
    public locationID: number;
    public sku: string;
    public name: string;
}

class MaterialInventoryService extends BaseService {
    private readonly BASE_URL: string = "/api/Inventory";

    getUniqueInventoryItem(sku: string, locationID: number, fieldList: string[], quantityList: string[]): Promise<EYDInventoryItem> {

        let qryParams = new DynamicEntityQueryFilterParameters();

        if (quantityList?.length > 0) {
            qryParams.fields = fieldList.concat(quantityList);

            qryParams.filter = new DynamicEntityQueryFilter();
            qryParams.filter.filterType = DynamicEntityQueryFilterType.AND;
            qryParams.filter.operandID = "";

            const skuFilter = new DynamicEntityQueryFilter();
            skuFilter.filterType = DynamicEntityQueryFilterType.EQ;
            skuFilter.operandID = IntrinsicInventoryItemCustomFieldID.SKU;
            skuFilter.operandValue = sku;

            const quantityOpFilter = new DynamicEntityQueryFilter();
            quantityOpFilter.filterType = DynamicEntityQueryFilterType.OR;
            quantityOpFilter.operandID = "";
            quantityOpFilter.operands = [];

            for (let quantityid of quantityList) {

                let locationFilter = new DynamicEntityQueryFilter();
                locationFilter.filterType = DynamicEntityQueryFilterType.EQ;
                locationFilter.operandID = IntrinsicInventoryItemCustomFieldID.LocationID;
                locationFilter.operandValue = locationID.toString();

                let quantityFilter = new DynamicEntityQueryFilter();
                quantityFilter.filterType = DynamicEntityQueryFilterType.EQ;
                quantityFilter.operandID = quantityid;
                quantityFilter.operands = [locationFilter];

                quantityOpFilter.operands.push(quantityFilter);
            }
            qryParams.filter.operands = [skuFilter, quantityOpFilter];

        } else {
            qryParams.fields = fieldList;

            qryParams.filter = new DynamicEntityQueryFilter();
            qryParams.filter.filterType = DynamicEntityQueryFilterType.EQ;
            qryParams.filter.operandID = IntrinsicInventoryItemCustomFieldID.SKU;
            qryParams.filter.operandValue = sku;
        }

        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryInventoryItems`, qryParams)
                .then((response) => {
                    let items = response.data.value as EYDInventoryItemDTO[];
                    
                    if (items && items[0]) {
                        let cVal = new EYDInventoryItem(); 

                        for (var k in items[0].fieldValues) {
                            let fieldValue = Object.assign(new EYDItemFieldValue(), items[0].fieldValues[k]);
                            cVal.setFieldValue(k, fieldValue);
                        }
                        for (var k in items[0].quantityValues) {
                            let quantityValue = Object.assign(new Array<EYDQuantityFieldValue>(), items[0].quantityValues[k]);
                            cVal.setQuantityValues(k, quantityValue);
                        }
                        cVal.itemID = items[0].itemId;

                        resolve(cVal);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    queryInventoryItems(fieldList: string[] = []): Promise<EYDInventoryItem[]> {
        let qryParams = new DynamicEntityQueryFilterParameters();
        qryParams.fields = fieldList;
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryInventoryItems`, qryParams)
                .then((response) => {
                    let itemValues = response.data.value as EYDInventoryItemDTO[];
                    let result: EYDInventoryItem[] = null;
                    if (itemValues) {
                        result = [];
                        for (let item of itemValues) {
                            let cVal = new EYDInventoryItem();

                            for (var k in item.fieldValues) {
                                let fieldValue = Object.assign(new EYDItemFieldValue(), item.fieldValues[k]);
                                cVal.setFieldValue(k, fieldValue);
                            }
                            for (var k in item.quantityValues) {
                                let quantityValue = Object.assign(new Array<EYDQuantityFieldValue>(), item.quantityValues[k]);
                                cVal.setQuantityValues(k, quantityValue);
                            }
                            cVal.itemID = item.itemId;
                            result.push(cVal);
                        }
                    }
                    resolve(result);
                })
                .catch((error) => this.handleError(error, reject));
        });
    }
    getInventoryItemById(id: string, fieldList: string[]): Promise<EYDInventoryItem> {
        let qryParams = new DynamicEntityQueryFilterParameters();

        qryParams.fields = fieldList;

        qryParams.filter = new DynamicEntityQueryFilter();
        qryParams.filter.filterType = DynamicEntityQueryFilterType.EQ;
        qryParams.filter.operandID = IntrinsicInventoryItemCustomFieldID.ItemID;
        qryParams.filter.operandValue = id;

        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/QueryInventoryItems`, qryParams)
                .then((response) => {
                    let items = response.data.value as EYDInventoryItemDTO[];
                    
                    if (items && items[0]) {
                        let cVal = new EYDInventoryItem();

                        for (var k in items[0].fieldValues) {
                            let fieldValue = Object.assign(new EYDItemFieldValue(), items[0].fieldValues[k]);
                            cVal.setFieldValue(k, fieldValue);
                        }
                        for (var k in items[0].quantityValues) {
                            let quantityValue = Object.assign(new Array<EYDQuantityFieldValue>(), items[0].quantityValues[k]);
                            cVal.setQuantityValues(k, quantityValue);
                        }
                        cVal.itemID = items[0].itemId;
                        resolve(cVal);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertInventoryItem(data: EYDInventoryItem): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/upsertInventoryItemData`, {
                itemID: data.itemID,
                fieldValues: data.getFieldValuesMapped(),
                quantityValues: data.getQuantityValuesMapped()
            })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    incrementInventoryItem(data: EYDInventoryItem): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/incrementInventoryItemData`, {
                itemID: data.itemID,
                fieldValues: data.getFieldValuesMapped(),
                quantityValues: data.getQuantityValuesMapped()
            })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    deleteInventoryItem(itemID: number): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<any>(`${this.BASE_URL}/deleteInventoryItem/`, { params: { invID: itemID } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new MaterialInventoryService();
