import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { BusinessLocation } from "eyd.models-ui";
import { EYAMApiResult } from "eyam-webui-models";

class BusinessLocationsService extends BaseService {
    private readonly BASE_URL: string = "/api/BusinessLocations";

    getAllBusinessLocations(): Promise<BusinessLocation[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetAllBusinessLocations`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getBusinessLocation(id: number): Promise<BusinessLocation> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<BusinessLocation>>(`${this.BASE_URL}/GetBusinessLocation/${id}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new BusinessLocationsService();
