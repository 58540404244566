import { FormViewType, DynamicEntityFieldType, DynamicEntityFieldValue, FieldRole, FieldTargetType } from "eyam-webui-models";
import { EYDAppView, EYDCustomField, EYDCustomFieldDependency, EYDFormView, EYDFormViewCustomFieldAssociation, EYDLookupTableValue } from "eyd.models-ui";
import AppConfigService from "../../../../Services/AppConfigService";
import CustomFieldsService from "../../../../Services/CustomFieldsService";
import LookupTablesService from "../../../../Services/LookupTablesService";

export interface InventoryFormConfigData {
    appView: EYDAppView,
    formView: EYDFormView;
    customFieldMap: Map<string, EYDCustomField>;
    fieldAssociations: EYDFormViewCustomFieldAssociation[];
    lookupValuesMap: Map<string, Map<string, EYDLookupTableValue>>;
    formFieldDependencies: EYDCustomFieldDependency[];
    usedFieldsList: string[];
}

export const applyDataDefaults = (
    assetVals: Map<string, DynamicEntityFieldValue>,
    fieldAssociations: EYDFormViewCustomFieldAssociation[],
    customFieldMap: Map<string, EYDCustomField>
) => {
    //apply default and overrides

    for (let cFieldAsoc of fieldAssociations) {
        var cfDefDat = customFieldMap.get(cFieldAsoc.customFieldID);

        if (cfDefDat) {
            if (cFieldAsoc.defaultValue && (cFieldAsoc.overrideWithDefaultValue || !assetVals.has(cFieldAsoc.customFieldID))) {
                let defOrOvrddVal = new DynamicEntityFieldValue();

                let ovrdVal: any = null;

                switch (cfDefDat.fieldType) {
                    case DynamicEntityFieldType.Boolean:
                        ovrdVal = cFieldAsoc.defaultValue === "true" || cFieldAsoc.defaultValue === "True";
                        break;
                    case DynamicEntityFieldType.Long:
                    case DynamicEntityFieldType.Integer:
                        let numVal = parseInt(cFieldAsoc.defaultValue);
                        ovrdVal = isNaN(numVal) ? 0 : numVal;
                        break;
                    case DynamicEntityFieldType.Float:
                        let numFltVal = parseFloat(cFieldAsoc.defaultValue);
                        ovrdVal = isNaN(numFltVal) ? 0 : numFltVal;
                        break;
                    case DynamicEntityFieldType.Date:
                        if (cFieldAsoc.defaultValue === "NOW") {
                            ovrdVal = new Date().toISOString().substr(0, 10);
                        } else {
                            ovrdVal = cFieldAsoc.defaultValue;
                        }
                        break;
                    case DynamicEntityFieldType.Array:
                        ovrdVal = cFieldAsoc.defaultValue?.split(",");
                        break;

                    default:
                        ovrdVal = cFieldAsoc.defaultValue;
                        break;
                }

                defOrOvrddVal.setObjectValue(ovrdVal, cfDefDat.fieldType);
                assetVals.set(cFieldAsoc.customFieldID, defOrOvrddVal);
            }
        } else {
            throw "GENERIC_ERROR_ALERT-CUSTOM_FIELD_NOT_FOUND";
        }
    }
};

export const loadConfigurationData = async (appViewID: string): Promise<InventoryFormConfigData> => {
    let cApView = await AppConfigService.getAppViewByID(appViewID);
    let cFormViews = await AppConfigService.getFormViewsByAppViewID(appViewID);

    if (cFormViews?.length == 0){
        throw "GENERIC_ERROR_ALERT-LOADING_CONFIGURATION_FAILED"
    }
    
    let cFormView = cFormViews.find(fv => fv.viewType == FormViewType.MaterialView)
    let asocs = await AppConfigService.getFormViewCustomFieldAssociationByFormViewID(cFormView.id);
    let fieldDeps = await AppConfigService.getAllFieldDependenciesForFormView(cFormView.id);

    let currentFieldList = asocs.map((a) => a.customFieldID);

    for (let fldDep of fieldDeps) {
        if (!currentFieldList.some((cf) => cf === fldDep.customFieldID)) {
            currentFieldList.push(fldDep.customFieldID);
        }

        if (!currentFieldList.some((cf) => cf === fldDep.dependsOnCustomFieldID)) {
            currentFieldList.push(fldDep.dependsOnCustomFieldID);
        }
    }

    let currentFields = await CustomFieldsService.queryCustomFields({
        includeIDs: asocs.map((a) => a.customFieldID),
        targetType: FieldTargetType.Inventory
    });

    let cfDict = new Map<string, EYDCustomField>();
    let lkpTablesToLoad: string[] = [];

    for (let cf of currentFields) {
        cfDict.set(cf.customFieldID, cf);

        if (cf.lookupTableID && cf.lookupTableID != "00000000-0000-0000-0000-000000000000") {
            if (!lkpTablesToLoad.some((id) => id == cf.lookupTableID)) {
                lkpTablesToLoad.push(cf.lookupTableID);
            }
        }
    }

    let lkpTableDicts = new Map<string, Map<string, EYDLookupTableValue>>();

    for (let lkpTblId of lkpTablesToLoad) {
        let cLkpTblValues = await LookupTablesService.getAllValuesForLookupTable(lkpTblId);
        let lkpMap = new Map<string, EYDLookupTableValue>();

        for (let lkpVal of cLkpTblValues) {
            lkpMap.set(lkpVal.id, lkpVal);
        }

        lkpTableDicts.set(lkpTblId, lkpMap);
    }

    return {
        appView: cApView,
        formView: cFormView,
        customFieldMap: cfDict,
        fieldAssociations: asocs,
        lookupValuesMap: lkpTableDicts,
        formFieldDependencies: fieldDeps,
        usedFieldsList: currentFields.filter((c) => c.fieldRole === FieldRole.Quantity).map((c) => c.customFieldID)
    };
};

export const checkFieldNullorEmpty = (fieldValue: DynamicEntityFieldValue, type: DynamicEntityFieldType) => {

    let value = fieldValue?.getObjectValue(type)

    if (value !== null) {

        switch (type) {
            case DynamicEntityFieldType.String:
            case DynamicEntityFieldType.LongString:
            case DynamicEntityFieldType.Date:
            case DynamicEntityFieldType.Lookup:
            case DynamicEntityFieldType.Array:
                value = value as string;
                if (value.length > 0) {
                    return false;
                }
                break;
            case DynamicEntityFieldType.Boolean:
            case DynamicEntityFieldType.Integer:
            case DynamicEntityFieldType.Float:
            case DynamicEntityFieldType.Long:
                return false;
        };
    }

    return true;

}
