import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { EYDPhoto, PhotoType } from "eyd.models-ui";


class AssetPhotosService extends BaseService {
    private readonly BASE_URL: string = "/api/AssetPhotos";

    getPhotoByID(id: string, type: PhotoType = PhotoType.FullSize): Promise<EYDPhoto> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetPhotoByID`, { params: { id, type } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAssetPhotos(assetID: string, type: PhotoType = PhotoType.FullSize): Promise<EYDPhoto[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetAssetPhotos`, { params: { assetID, type } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertAssetPhoto(pd: EYDPhoto): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/UpsertAssetPhoto`, pd)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    deletePhotoByID(photoID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<any>(`${this.BASE_URL}/DeletePhotoByID`, { params: { photoID } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    deleteAssetPhotos(assetID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<any>(`${this.BASE_URL}/DeleteAssetPhotos, `, { params: { assetID } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new AssetPhotosService();
