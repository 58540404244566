import { NavigationObserver, useNavigationContext } from "eyam-webui-components";
import { EYDItemCount, EYDItemCountSession, EYDProcessOrderItem } from "eyd.models-ui";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import ProcessOrdersService from "../../../../Services/ProcessOrdersService";
import { ConsumeItemCountEdit } from "./ConsumeItemCountEdit";
import { ConsumeItemCountScan } from "./ConsumeItemCountScan";
import { useNavigate } from "react-router-dom";


enum ConsumeCountOperationStep {
    Scan = 1,
    Edit = 2,
}

const ConsumeItemCountView: React.FunctionComponent<{}> = () => {
    const [currentStep, setCurrentStep] = useState(ConsumeCountOperationStep.Scan);
    const [itemCountID, setItemCountID] = useState<number>();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { t } = useTranslation();

    const mounted = useRef(false);
    const navContext = useNavigationContext();
    const navigate = useNavigate();


    useEffect(() => {
        mounted.current = true;
        navContext.setTitle(t("GENERIC_LABEL-ITEM_COUNT"));
        navContext.navigationSubject.attach(() => { onBackButtonClick() });
        return () => {
            mounted.current = false;
            navContext.navigationSubject.detach();
        };
    }, [currentStep]);

    const onBackButtonClick: NavigationObserver = () => {
        if (currentStep === ConsumeCountOperationStep.Edit) {
            setCurrentStep(ConsumeCountOperationStep.Scan);
        } else if (currentStep === ConsumeCountOperationStep.Scan) {
            navigate(-1);
            navContext.navigationSubject.detach();
        }
    }

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            console.error(details);
            EYDNotificationService.showError(errMsg, details);
        }
    }

    const onSubmit = (countID) => {
        setItemCountID(countID);
        setCurrentStep(ConsumeCountOperationStep.Edit);
    }

    const renderCurrentStep = (): JSX.Element => {

        switch (currentStep) {
            case ConsumeCountOperationStep.Scan:
                return (
                    <ConsumeItemCountScan
                        onSubmit={onSubmit} />
                );

            case ConsumeCountOperationStep.Edit:
                return (
                    <ConsumeItemCountEdit
                        currentItemCountID={itemCountID}
                        loadingOverride={isProcessing}
                        handleError={(msg) => { handleError(msg, ""); setCurrentStep(ConsumeCountOperationStep.Scan); }}
                        handleCancelEditMode={() => setCurrentStep(ConsumeCountOperationStep.Scan)}
                    />
                );
        }
    }

    return (
        <div className="Info-view">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (renderCurrentStep())}
        </div>
    );
};
export default ConsumeItemCountView;