import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Loader, Grid, Header, Button, Icon, LabelProps, SemanticShorthandItem } from "semantic-ui-react";
import { DynamicEntityForm } from "eyam-webui-components";
import { FormActionType, FormViewType, BaseGlobalSettings, DynamicEntityFieldValue, FieldRole, QuantityFieldActionType } from "eyam-webui-models";
import { EYDInventoryItem, EYDQuantityFieldValue, ProcessOrderOperatedQuantity } from "eyd.models-ui";
import { applyDataDefaults, checkFieldNullorEmpty, InventoryFormConfigData, loadConfigurationData } from "../Utils/InventoryFormUtils";

import MaterialInventoryService from "../../../../Services/MaterialInventoryService";
import GlobalSettingsService from "../../../../Services/GlobalSettingsService";

interface DynamicItemEditProps {
    currentItemSKU: string;
    locationID: number;
    loadingOverride: boolean;
    handleItemSaved: (quantities) => void;
    handleItemError: (msg) => void;
}

export const DynamicItemEdit: React.FunctionComponent<DynamicItemEditProps> = (props) => {

    const [configData, setConfigData] = useState<InventoryFormConfigData>(null);
    const [inventoryItem, setInventoryItem] = useState<EYDInventoryItem>(null);
    const [currentEntityValues, setCurrentEntityValues] = useState<Map<string, DynamicEntityFieldValue>>(new Map<string, DynamicEntityFieldValue>());

    const [isItemLoading, setIsItemLoading] = useState<boolean>(false);
    const [isConfigLoading, setIsConfigLoading] = useState<boolean>(false);
    const [dateFormat, setDateFormat] = useState<string>("DD-MM-YYYY");
    const [errorMap, setErrorMap] = useState<Map<string, SemanticShorthandItem<LabelProps>>>(new Map<string, SemanticShorthandItem<LabelProps>>());

    const { t } = useTranslation();
    const mounted = useRef(false);
    const rtParams = useParams<"id">();

    useEffect(() => {
        mounted.current = true;
        setIsItemLoading(true);

        const load = async () => {

            try {
                if (props.currentItemSKU && props.currentItemSKU !== "" && props.locationID) {
                    if (configData !== null && configData.customFieldMap !== null) {
                        let fieldList: string[] = [];
                        let quantityList: string[] = [];

                        for (let fieldId of configData.usedFieldsList) {
                            if (configData.customFieldMap.get(fieldId).fieldRole === FieldRole.Info) {
                                fieldList.push(fieldId);
                            } else {
                                quantityList.push(fieldId);
                            }
                        }

                        const item = await MaterialInventoryService.getUniqueInventoryItem(props.currentItemSKU, props.locationID, fieldList, quantityList);

                        if (mounted.current) {

                            let setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.DateFormat);
                            if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                                setDateFormat(setting.settingValue.valueText);
                            }

                            var values = new Map<string, DynamicEntityFieldValue>();
                            if (item) {

                                for (let fieldId of fieldList) {
                                    values.set(fieldId, item.fieldValues.get(fieldId));
                                }

                                for (let fieldId of quantityList) {
                                    let dynamicQuantity = new DynamicEntityFieldValue();
                                    if (configData.fieldAssociations.find(a => a.customFieldID === fieldId).action === QuantityFieldActionType.CurrentValue && item.quantityValues.has(fieldId)) {
                                        let qValue = item.quantityValues.get(fieldId)?.find(q => q.locationID === props.locationID)?.valueDouble;
                                        dynamicQuantity.setObjectValue(qValue, configData.customFieldMap.get(fieldId).fieldType);
                                    }
                                    else {
                                        dynamicQuantity.setObjectValue('0', configData.customFieldMap.get(fieldId).fieldType);
                                    }
                                    values.set(fieldId, dynamicQuantity);
                                }
                            } else {
                                props.handleItemError(t("PAGE_LABEL-PAGE_INVENTORY_ERROR_ALERT_NOT_FOUND"))
                            }

                            applyDataDefaults(values, configData.fieldAssociations, configData.customFieldMap);
                            setCurrentEntityValues(values);
                            setInventoryItem(item);
                        }
                    }
                }
            } catch (e) {
                handleError(t("GENERIC_ERROR_ALERT-WENT_WRONG"), e);
            } finally {
                setIsItemLoading(false);
            }
        };

        load();

        return () => {
            mounted.current = false;
        }

    }, [props.currentItemSKU, configData]);

    useEffect(() => {
        mounted.current = true;
        setIsConfigLoading(true);

        let load = async () => {
            try {
                let config = await loadConfigurationData(rtParams.id);

                if (mounted.current) {
                    setConfigData(config);
                }
            } catch (e) {
                handleError(t("GENERIC_ERROR_ALERT-LOADING_CONFIGURATION_FAILED"), e);
            } finally {
                setIsConfigLoading(false);
            }
        };

        load();

        return () => {
            mounted.current = false;
        }
    }, []);

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            props.handleItemError(errMsg);
        }
    };

    const areFieldsValid = (entityValues: Map<string, DynamicEntityFieldValue>, field = null) => {

        var map = new Map(errorMap)
        var isValid = true;
        var fieldAssocs = configData?.fieldAssociations;

        if (field !== null) {
            fieldAssocs = fieldAssocs.filter(a => a.customFieldID === field);
        }

        for (let assoc of fieldAssocs) {
            let customField = configData?.customFieldMap.get(assoc.customFieldID);

            if (customField?.fieldRole === FieldRole.Info) {

                if (assoc?.isRequired) {
                    if (!entityValues.has(assoc.customFieldID) || checkFieldNullorEmpty(entityValues.get(assoc.customFieldID), customField?.fieldType)) {
                        map.set(assoc.customFieldID, { content: t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_REQUIRED"), pointing: "above" });
                        isValid = false;
                    }
                    else {
                        map.set(assoc.customFieldID, null);
                    }
                }
            }
            else {
                let itemQVal = inventoryItem.getQuantityValue(assoc.customFieldID, props.locationID)?.valueDouble;
                itemQVal = itemQVal ? itemQVal : 0;
                let currentQVal = +entityValues.get(assoc.customFieldID)?.getObjectValue(customField?.fieldType);
                currentQVal = currentQVal ? currentQVal : 0;

                let checkQVal = 0;

                if (assoc.action === QuantityFieldActionType.UseDecrement) {
                    checkQVal = itemQVal - currentQVal;
                }
                else if (assoc.action === QuantityFieldActionType.UseIncrement) {
                    checkQVal = itemQVal + currentQVal;
                }
                else {
                    checkQVal = currentQVal;
                }

                if (assoc?.isRequired && (!entityValues.has(assoc.customFieldID) || checkFieldNullorEmpty(entityValues.get(assoc.customFieldID), customField?.fieldType) || currentQVal === 0)) {
                    map.set(assoc.customFieldID, { content: t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_REQUIRED"), pointing: "above" });
                    isValid = false;
                }
                else if (customField.minValue !== null && checkQVal < customField.minValue) {
                    map.set(assoc.customFieldID, { content: t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_BELOW_MIN"), pointing: "above" });
                    isValid = false;
                }
                else if (customField.maxValue !== null && checkQVal > customField.maxValue) {
                    map.set(assoc.customFieldID, { content: t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_ABOVE_MAX"), pointing: "above" });
                    isValid = false;
                }
                else {
                    map.set(assoc.customFieldID, null);
                }
            }
        }

        setErrorMap(map);
        return isValid;
    };

    const handleItemSave = async () => {
        setIsItemLoading(true);
        try {
            if (areFieldsValid(currentEntityValues)) {
                let incItem: EYDInventoryItem = Object.create(inventoryItem);
                let updItem: EYDInventoryItem = Object.create(inventoryItem);
                incItem.quantityValues = new Map<string, EYDQuantityFieldValue[]>();
                updItem.quantityValues = new Map<string, EYDQuantityFieldValue[]>();

                let quantities: ProcessOrderOperatedQuantity[] = [];

                currentEntityValues.forEach((val, key) => {

                    if (inventoryItem.fieldValues.has(key)) {
                        incItem.setFieldValue(key, val);
                        updItem.setFieldValue(key, val);
                    }
                    else {
                        if (val.getObjectValue(configData.customFieldMap.get(key).fieldType) !== '0') {
                            let quantity = new EYDQuantityFieldValue();
                            let usedQuantity = new ProcessOrderOperatedQuantity();
                            usedQuantity.customFieldID = key;

                            if (inventoryItem.quantityValues.has(key)) {
                                quantity = inventoryItem.getQuantityValue(key, props.locationID);
                            }
                            else {
                                quantity.locationID = props.locationID;
                            }

                            if (configData?.fieldAssociations.find(a => a.customFieldID === key)?.action === QuantityFieldActionType.UseDecrement) {
                                quantity.valueDouble = -1 * val.getObjectValue(configData.customFieldMap.get(key).fieldType);
                                incItem.addQuantityValue(key, quantity);
                                usedQuantity.action = QuantityFieldActionType.UseDecrement;
                            }
                            else if (configData?.fieldAssociations.find(a => a.customFieldID === key)?.action === QuantityFieldActionType.UseIncrement) {
                                quantity.valueDouble = val.getObjectValue(configData.customFieldMap.get(key).fieldType);
                                incItem.addQuantityValue(key, quantity);
                                usedQuantity.action = QuantityFieldActionType.UseIncrement;
                            }
                            else {
                                quantity.valueDouble = val.getObjectValue(configData.customFieldMap.get(key).fieldType);
                                updItem.addQuantityValue(key, quantity);
                                usedQuantity.action = QuantityFieldActionType.CurrentValue;
                            }
                            usedQuantity.valueDouble = val.getObjectValue(configData.customFieldMap.get(key).fieldType);
                            quantities.push(usedQuantity);
                        }
                    }

                });

                let id = await MaterialInventoryService.incrementInventoryItem(incItem);
                id = await MaterialInventoryService.upsertInventoryItem(updItem);
                props.handleItemSaved(quantities);
            }
        } catch (e) {
            handleError(t("GENERIC_ERROR_ALERT-SUBMIT_FAILED"), e);
        } finally {
            setIsItemLoading(false);
        }
    };

    const handleFieldChange = (field, type, cData) => {
        var newCurrentEntityValues = new Map<string, DynamicEntityFieldValue>(currentEntityValues.set(field, cData))
        setCurrentEntityValues(newCurrentEntityValues);
        areFieldsValid(newCurrentEntityValues, field);
    };

    return (
        <div className="common-view">
            {props.loadingOverride || isItemLoading || isConfigLoading ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column>
                            <Header icon className="common-view__view-header-logo">
                                <Icon name={configData?.appView?.icon as any} />
                            </Header>
                            <DynamicEntityForm
                                customFieldData={configData?.customFieldMap}
                                errorMap={errorMap}
                                viewAssociationData={configData?.fieldAssociations}
                                fieldDependencyData={configData?.formFieldDependencies}
                                lookupData={configData?.lookupValuesMap}
                                valueMap={currentEntityValues}
                                onFieldChanged={handleFieldChange}
                                dateFormat={dateFormat}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {configData?.appView?.actions?.some(a => a.actionType === FormActionType.HasSubmitBtn) &&
                        <Grid.Row verticalAlign="middle">
                            <Grid.Column>
                                <Button fluid primary
                                    content={t("GENERIC_BUTTON-SUBMIT")}
                                    onClick={() => { handleItemSave(); }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            )}
        </div>
    );
};
