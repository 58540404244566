import { EndSessionRequest, InteractionType, AuthenticationResult } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { EYAMApp, LoaderWrapper, NavigationGroup, NotificationService } from "eyam-webui-components";
import { AuthProfile } from "eyam-webui-components";
import React, { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AuthAreas } from "./Auth/AuthAreas";
import { AppRoutes } from "./AppRoutes";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import UtilityService from "./Services/UtilityService";
import "../styles/abstracts/_mixins.scss";
import "../styles/global-styles.scss";
import "../styles/layout.scss";
import "../styles/shared/common-view.scss";
import HomeView from "./UI/HomeView";
import DynamicAssetView from "./UI/Modules/Assets/DynamicForm/DynamicAssetView";
import DynamicItemView from "./UI/Modules/Inventory/DynamicForm/DynamicItemView";
import DynamicProcessOrderView from "./UI/Modules/ProcessOrder/Dynamic/DynamicProcessOrderView";
import SettingsView from "./UI/Modules/Settings/SettingsView";
import SupportView from "./UI/Modules/Support/SupportView";
import { API_SCOPE, EYDPCA } from "./Auth/AuthConfig";
import ConsumeItemCountView from "./UI/Modules/ItemCount/Consume/ConsumeItemCountView";
import { EYDViewGroup } from "eyd.models-ui";
import AppConfigService from "./Services/AppConfigService";
import EYDNotificationService from "./Services/EYDNotificationService";
import { AppViewTargetType, AppViewType } from "eyam-webui-models";
import NotAllowed from "./UI/Modules/Common/NotAllowed";
import NoMatchPage from "./UI/Modules/Common/NoMatchPage";
import DynamicAssetListView from "./UI/Modules/Assets/DynamicList/DynamicAssetListView";

const App: React.FunctionComponent<{}> = () => {
    const [cProfile, setAuthData] = useState<AuthProfile>(null);
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [authRules, setAuthRules] = useState<{ [key: string]: string[] }>({});
    const [viewGroups, setViewGroups] = useState<EYDViewGroup[]>([]);
    const [utilityError, setUtilityError] = useState(false);


    useEffect(() => {
        const initAuth = async () => {

            try {

                let msalAccount = await acquireMsalAccount();

                const { account, accessToken } = msalAccount;
                const claims = JSON.parse(window.atob(accessToken.split(".")[1]));

                var profile: AuthProfile = {
                    roles: claims.roles,
                    displayName: account.name,
                    userId: account.homeAccountId
                }

                setAuthData(profile);
            } catch (e) {
                console.error("Failed to load auth profile");
                setUtilityError(true);
            }

            try {
                let auth = await UtilityService.getAuthRules();

                setAuthRules(auth);

            } catch (e) {
                setUtilityError(true);
                console.error("Failed to load app auth rules");
            }
        }

        initAuth();

    }, []);

    const acquireMsalAccount = async () => {
        const activeAccount = EYDPCA.getActiveAccount();
        const accounts = EYDPCA.getAllAccounts();

        if (!activeAccount && accounts.length === 0) {
            throw "User not logged in";
        }

        const request = {
            scopes: [API_SCOPE],
            account: activeAccount || accounts[0]
        };

        let authRes: AuthenticationResult = null;

        try {

            authRes = await EYDPCA.acquireTokenSilent(request);

        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {

                try {
                    await EYDPCA.acquireTokenRedirect(request);
                } catch (err2) {
                    console.log(err2);
                }
            } else {
                throw err;
            }
        }

        return authRes;
    };

    const logoutHandler = async () => {
        const allAccounts = EYDPCA.getAllAccounts();
        const account = EYDPCA.getActiveAccount() || allAccounts[0];
        const logoutRequest: EndSessionRequest = {
            account,
            postLogoutRedirectUri: window.location.origin
        }
        setIsProcessing(true);
        try {
            await UtilityService.cancelAccessToken();
            await EYDPCA.logoutRedirect(logoutRequest);
        } catch (error) {
            NotificationService.showError(error + "", t("GENERIC_BUTTON_OK"), error)
        }
        finally {
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        let mounted = true;
        setIsProcessing(true);

        const initData = async () => {
            let groups: EYDViewGroup[] = [];

            if (cProfile?.roles?.length > 0) {
                groups = await AppConfigService.getAllViewGroups(true, true);
            }
            return { groups }
        }

        initData().then((result) => {
            if (mounted) {
                result.groups.sort((a, b) => a.displayOrder - b.displayOrder);
                setViewGroups(result.groups);
            }
        }).catch((e) => {
            if (mounted) {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_APP_VIEWS_FAILED"), e);
            }

        }).finally(() => {
            setIsProcessing(false);
        })

        return () => {
            mounted = false
        }

    }, [cProfile]);

    let navigationConfig: NavigationGroup[] = [];

    navigationConfig.push({
        name: "",
        area: AuthAreas.MAIN_AREA,
        items: [
            { viewTitle: t("GENERIC_LABEL-HOME"), view: <HomeView />, path: AppRoutes.HOME }

        ]
    });

    for (let group of viewGroups) {
        if (group.appViews?.length > 0) {
            group.appViews.sort((a, b) => a.displayOrder - b.displayOrder);

            for (let appView of group.appViews) {
                if (appView.isEnabled) {
                    if (appView.viewType === AppViewType.FormView) {
                        if (appView.targetType === AppViewTargetType.AssetView) {
                            navigationConfig[0].items.push({ viewTitle: t(appView.localizedKey, appView.name), view: <DynamicAssetView />, path: AppRoutes.ASSET_FORM.replace(":id", `${appView.id}`), menuOnly: true });
                        } else if (appView.targetType === AppViewTargetType.MaterialView) {
                            navigationConfig[0].items.push({ viewTitle: t(appView.localizedKey, appView.name), view: <DynamicItemView />, path: AppRoutes.MATERIAL_FORM.replace(":id", `${appView.id}`), menuOnly: true });
                        } else if (appView.targetType === AppViewTargetType.ProcessOrderView) {
                            navigationConfig[0].items.push({ viewTitle: t(appView.localizedKey, appView.name), view: <DynamicProcessOrderView />, path: AppRoutes.PROCESS_ORDER_FORM.replace(":id", `${appView.id}`), menuOnly: true });
                        }
                    }
                    if (appView.viewType === AppViewType.ListView) {
                        if (appView.targetType === AppViewTargetType.AssetView) {
                            navigationConfig[0].items.push({ viewTitle: t(appView.localizedKey, appView.name), view: <DynamicAssetListView />, path: AppRoutes.ASSET_LIST.replace(":id", `${appView.id}`), menuOnly: true });
                        }
                    }
                }
            }
        }
    }

    navigationConfig[0].items.push(
        { viewTitle: t("GENERIC_LABEL-FORM"), view: <DynamicAssetView />, path: AppRoutes.ASSET_FORM, routeOnly: true },
        { viewTitle: t("GENERIC_LABEL-FORM"), view: <DynamicItemView />, path: AppRoutes.MATERIAL_FORM, routeOnly: true },
        { viewTitle: t("GENERIC_LABEL-FORM"), view: <DynamicProcessOrderView />, path: AppRoutes.PROCESS_ORDER_FORM, routeOnly: true },
        { viewTitle: t("GENERIC_LABEL-LIST"), view: <DynamicAssetListView />, path: AppRoutes.ASSET_LIST, routeOnly: true },
        { viewTitle: t("GENERIC_LABEL-ITEM_COUNT"), view: <ConsumeItemCountView />, path: AppRoutes.ITEM_COUNT_CONSUME },
        { viewTitle: t("GENERIC_LABEL-SETTINGS"), view: <SettingsView />, path: AppRoutes.SETTINGS },
        { viewTitle: t("GENERIC_LABEL-SUPPORT"), view: <SupportView />, path: AppRoutes.SUPPORT }
    );

    if (utilityError) {
        return (
            <>Something went wrong.</>
        );
    } else {

        return (
            <LoaderWrapper inverted active={isProcessing}>
                {!isProcessing &&
                    <EYAMApp
                        navigationConfig={navigationConfig}
                        areaAccessRules={authRules}
                        userProfile={cProfile}
                        notAllowedRoute={AppRoutes.NOT_ALLOWED}
                        renderNotAllowedPage={() => <NotAllowed />}
                        notFoundRoute={AppRoutes.NOT_FOUND}
                        renderNotFoundPage={() => <NoMatchPage />}
                        homeRoute={AppRoutes.HOME}
                        appLogoPath={"/images/ey_logo_dark_200.png"}
                        renderSceneError={() => { return <>{t("GENERIC_ERROR_ALERT-WENT_WRONG")}</> }}
                        onLogout={logoutHandler}
                        sidebarWidth={250}
                    />
                }
            </LoaderWrapper>
        )
    }
}

export const AppAuthWrapper: React.FunctionComponent<{}> = () => {

    const authRequest = {
        scopes: ["openid", "profile"]
    };
    const { t } = useTranslation();

    const renderAuthLoader = () => {
        return <Dimmer active><Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active>{t("GENERIC-AUTENTICATING")}</Loader></Dimmer>
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={() => <div>error</div>}
            loadingComponent={() => renderAuthLoader()}>
            <App />
        </MsalAuthenticationTemplate>
    );
};


