import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { NavigationObserver, useNavigationContext } from "eyam-webui-components";
import { EYDAppView } from "eyd.models-ui";
import { DynamicItemScan } from "./DynamicItemScan";
import { DynamicItemEdit } from "./DynamicItemEdit";

import EYDNotificationService from "../../../../Services/EYDNotificationService";
import AppConfigService from "../../../../Services/AppConfigService";

enum DynamicOperationStep {
    ScanItem = 1,
    EditItem = 2
}

const DynamicItemView: React.FunctionComponent<{}> = () => {
    const [currentStep, setCurrentStep] = useState(DynamicOperationStep.ScanItem);
    const [currentSKUValue, setCurrentSKUValue] = useState<string>(null);
    const [appView, setAppView] = useState<EYDAppView>(null);
    const [locationID, setLocationID] = useState<number>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const { t } = useTranslation();
    const mounted = useRef(false);
    const navContext = useNavigationContext();
    const navigate = useNavigate();
    const rtParams = useParams<"id">();

    useEffect(() => {
        mounted.current = true;
        const load = async () => {
            try {
                setIsProcessing(true);
                let appV = await AppConfigService.getAppViewByID(rtParams.id);
                navContext.setTitle(t(appV?.localizedKey, appV?.name));
                let cFormViews = await AppConfigService.getFormViewsByAppViewID(appV.id);
                if (cFormViews?.length == 0) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-NO_FORM_ASSOCIATION"));
                    navigate(`/home`);
                }
                if (mounted.current) {
                    setAppView(appV);
                }
            } catch (e) {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_APP_VIEW_FAILED"), e);
                navigate(`/home`);
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        };
    }, [rtParams.id]);


    useEffect(() => {
        mounted.current = true;
        navContext.setTitle(t(appView?.localizedKey, appView?.name));
        navContext.navigationSubject.attach(() => { onBackButtonClick() });

        return () => {
            mounted.current = false;
            navContext.navigationSubject.detach();
        };
    }, [currentStep]);

    const onBackButtonClick: NavigationObserver = () => {
        if (currentStep === DynamicOperationStep.EditItem) {
            setCurrentStep(DynamicOperationStep.ScanItem);
        } else if (currentStep === DynamicOperationStep.ScanItem) {
            navigate(-1);
            navContext.navigationSubject.detach();
        }
    }

    const handleError = (errMsg: string, details: any) => {
        console.error(details);
        EYDNotificationService.showError(errMsg, details);
    };

    const handleItemSaved = () => {
        setIsProcessing(false);
        EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_INVENTORY_SUCCESS_ALERT_ITEM_SAVE"));
        setCurrentStep(DynamicOperationStep.ScanItem);
        setCurrentSKUValue(null);
    };

    const skuSubmitted = (sku, locationID) => {
        setCurrentSKUValue(sku);
        setLocationID(locationID);
        setCurrentStep(DynamicOperationStep.EditItem);
    }

    const renderCurrentStep = (): JSX.Element => {
        switch (currentStep) {
            case DynamicOperationStep.ScanItem:
                return (
                    <DynamicItemScan
                        onSubmit={skuSubmitted} />
                );

            case DynamicOperationStep.EditItem:
                return (
                    <DynamicItemEdit
                        locationID={locationID}
                        currentItemSKU={currentSKUValue}
                        handleItemSaved={handleItemSaved}
                        loadingOverride={isProcessing}
                        handleItemError={(msg) => { setCurrentStep(DynamicOperationStep.ScanItem); handleError(msg, ""); }}
                    />
                );
        }
    };

    return <div className="dynamic-item-view">
        {isProcessing ? (
            <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
        ) : renderCurrentStep()}
    </div>;
};
export default DynamicItemView;
