import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { SupportData } from "eyd.models-ui";

class SupportService extends BaseService {
    private readonly BASE_URL: string = "/api/Support";

    submitSupportForm(data: SupportData): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/SubmitSupportForm`, data)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new SupportService();
