import { EYDLookupTable, EYDLookupTableValue } from "eyd.models-ui";
import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";

export class LookupTableValueFilter {
    area?: string;
    token?: string;
    id?: number;
}

class LookupTablesService extends BaseService {
    private readonly BASE_URL: string = "/api/LookupTables";

    getLookupTableValues(filter: LookupTableValueFilter): Promise<EYDLookupTableValue[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetLookupTableValues`, { params: { ...filter } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getLookupTableById(id: string): Promise<EYDLookupTable> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetLookupTableById`, { params: { id } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllLookupTableValues(): Promise<EYDLookupTableValue[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetAllLookupTableValues`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllLookupTables(): Promise<EYDLookupTable[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetAllLookupTables`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllValuesForLookupTable(ltId: string): Promise<EYDLookupTableValue[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/GetAllValuesForLookupTable`, { params: { ltId } })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertLookupTableValue(data: EYDLookupTableValue): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/UpsertLookupTableValue`, data)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertLookupTable(data: EYDLookupTable): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/UpsertLookupTable`, data)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsert(data: EYDLookupTable): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/Upsert`, data)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    deleteLookupTableValue(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<any>(`${this.BASE_URL}/DeleteLookupTableValue/${id}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    deleteLookupTable(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<any>(`${this.BASE_URL}/DeleteLookupTable/${id}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}

export default new LookupTablesService();
