import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SupportData } from "eyd.models-ui";

import EYDNotificationService from "../../../Services/EYDNotificationService";
import { Button, Form, Grid, Header, Icon, Loader } from "semantic-ui-react";
import SupportService from "../../../Services/SupportService";
import { NavigationObserver, useNavigationContext } from "eyam-webui-components";
import {useNavigate} from "react-router-dom";

const SupportView: React.FunctionComponent<{}> = () => {
    const [errorId, setErrorId] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { t } = useTranslation();

    const mounted = useRef(false);
    const navContext = useNavigationContext();
    const navigate=useNavigate();

    useEffect(() => {
        mounted.current = true;
        navContext.setTitle(t("GENERIC_LABEL-SUPPORT"));
        navContext.navigationSubject.attach(()=>{onBackButtonClick()});
        return () => {
            mounted.current = false;
        };
    }, []);

    const onBackButtonClick: NavigationObserver = () => {
        navigate(-1);
        navContext.navigationSubject.detach();
     }

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            console.error(details);
            EYDNotificationService.showError(errMsg, details);
        }
    }

    const handleSubmit = async () => {
        setIsProcessing(true);
        try {
            const data: SupportData = {
                errorId: errorId,
                description: description,
                sentBy: ""
            }
            const rez = await SupportService.submitSupportForm(data);
            if (mounted.current) {
                setIsProcessing(false);
                EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_SUPPORT_SUCCESS_ALERT_FORM_SENT") + rez);
            }
        } catch (e) {
            handleError(t("GENERIC_ERROR_ALERT-SUBMIT_FAILED"), e);
        } finally {
            if (mounted.current) {
                setIsProcessing(false);
            }
        }
    }

    return (
        <div className="suppoert-view">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable textAlign='center'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <br />
                            <Header icon>
                                <Icon name='phone' />
                                {/* {t("GENERIC_LABEL-SUPPORT")} */}
                                </Header>
                                <div className="support-forms-wrapper">
                                <Form>
                                    <Form.Input
                                        onChange={(_, data) => setErrorId(data.value as string)}
                                        defaultValue={errorId}
                                        label={t("PAGE_LABEL-PAGE_SUPPORT_LABEL_ERROR_ID")}
                                        placeholder={t("PAGE_LABEL-PAGE_SUPPORT_PLACEHOLDER_ERROR_ID")}
                                    />
                                    <Form.TextArea
                                        onChange={(_, data) => setDescription(data.value as string)}
                                        defaultValue={description}
                                        label={t("PAGE_LABEL-PAGE_SUPPORT_LABEL_DESCRIPTION")}
                                        placeholder={t("PAGE_LABEL-PAGE_SUPPORT_PLACEHOLDER_DESCRIPTION")}
                                    />
                                </Form>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Button fluid primary
                                 content={t("GENERIC_BUTTON-SUBMIT")}
                                 onClick={() => { handleSubmit(); }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </div>
    );
};
export default SupportView;