import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Grid, Loader, Pagination, PaginationProps } from "semantic-ui-react";
import { useNavigationContext } from "eyam-webui-components";
import { EYDAppView, EYDAsset, EYDListView, EYDListViewCustomFieldAssociation } from "eyd.models-ui";

import AppConfigService from "../../../../Services/AppConfigService";
import EYDNotificationService from "../../../../Services/EYDNotificationService";
import AssetsService from "../../../../Services/AssetsService";
import { AssetFormConfigData, loadFormConfigurationForList } from "../Utils/AssetFormUtils";
import GlobalSettingsService from "../../../../Services/GlobalSettingsService";
import { BaseGlobalSettings, DynamicEntityFieldType } from "eyam-webui-models";

import "../../../../../styles/components/card_style.scss"

interface DynamicAssetListProps {
    onItemClicked: (sn: string) => void;
}

const DynamicAssetList: React.FunctionComponent<DynamicAssetListProps> = (props) => {
    const [appView, setAppView] = useState<EYDAppView>(null);
    const [configData, setConfigData] = useState<AssetFormConfigData>(null);
    const [listView, setListView] = useState<EYDListView>(null);
    const [assets, setAssets] = useState<EYDAsset[]>([]);
    const [totalAssetsCount, setTotalAssetsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [customFieldList, setCustomFieldList] = useState<string[]>([]);
    const [dateFormat, setDateFormat] = useState<string>("DD-MM-YYYY");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [areAssetsLoading, setAreAssetsLoading] = useState<boolean>(false);

    const { t } = useTranslation();
    const mounted = useRef(false);
    const navigate = useNavigate();
    const rtParams = useParams<"id">();

    useEffect(() => {
        mounted.current = true;
        const load = async () => {
            try {
                setIsProcessing(true);

                if (mounted.current) {
                    let setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.DateFormat);
                    if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                        setDateFormat(setting.settingValue.valueText);
                    }

                    let appV = await AppConfigService.getAppViewByID(rtParams.id);
                    let cListViews = await AppConfigService.getListViewsByAppViewID(appV.id);
                    if (cListViews?.length === 0) {
                        EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-NO_LIST_ASSOCIATION"));
                        navigate(`/home`);
                    }
                    let cListView = cListViews[0];

                    let config = await loadFormConfigurationForList();
                    config.appView = appV;

                    let fieldList = cListView?.customFieldAssociations.map(cfa => cfa.customFieldID);

                    let totalAssets = await AssetsService.queryAssetsCount(cListView.listLogicalOperation)

                    setConfigData(config);
                    setAppView(appV);
                    setListView(cListView);
                    setCustomFieldList(fieldList);
                    setTotalAssetsCount(totalAssets);
                }
            } catch (e) {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_APP_VIEW_FAILED"), e);
                navigate(`/home`);
            } finally {
                setIsProcessing(false);
            }
        };
        load();

        return () => {
            mounted.current = false;
        };
    }, [rtParams.id]);

    useEffect(() => {
        mounted.current = true;

        const queryAssets = async () => {
            try {
                setAreAssetsLoading(true);
                if (mounted.current) {
                    if (listView && customFieldList?.length > 0) {
                        let cAssets = await AssetsService.queryAssetsWithDetails(customFieldList, [listView?.sortByCustomFieldID],
                            listView.sortAscending, 10, pageNumber, listView.listLogicalOperation);

                        setAssets(cAssets);
                    }
                }
            } catch (e) {
                if (mounted.current) {
                    EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_ASSETS_FAILED"), e);
                    navigate(`/home`);
                }
            } finally {
                setAreAssetsLoading(false);
            }
        };
        queryAssets();

        return () => {
            mounted.current = false
        }

    }, [pageNumber, listView, customFieldList]);

    const getCardFieldValue = (asset: EYDAsset, assoc: EYDListViewCustomFieldAssociation, index: number) => {
        let customField = configData.customFieldMap.get(assoc.customFieldID);
        let value: string = "";
        switch (customField.fieldType) {
            case DynamicEntityFieldType.String: value = asset.getFieldValue(assoc.customFieldID).valueText; break;
            case DynamicEntityFieldType.LongString: value = asset.getFieldValue(assoc.customFieldID).valueText; break;
            case DynamicEntityFieldType.Integer: value = asset.getFieldValue(assoc.customFieldID).valueInt.toString(); break;
            case DynamicEntityFieldType.Float: value = asset.getFieldValue(assoc.customFieldID).valueDouble.toString(); break;
            case DynamicEntityFieldType.Long: value = asset.getFieldValue(assoc.customFieldID).valueLong.toString(); break;
            case DynamicEntityFieldType.Boolean: value = asset.getFieldValue(assoc.customFieldID).valueBoolean ? t("GENERIC-LABEL-YES") : t("GENERIC-LABEL-NO"); break;
            case DynamicEntityFieldType.Date: value = asset.getObjectFieldValueByType(assoc.customFieldID, customField.fieldType, dateFormat); break;
            case DynamicEntityFieldType.Lookup:
                let ltV = configData.lookupValuesMap?.get(customField.lookupTableID)?.get(asset.getObjectFieldValueByType(assoc.customFieldID, customField.fieldType))
                value = t(ltV?.localizedKey, ltV?.name)
                break;
            case DynamicEntityFieldType.Array:
                let arrayVals = asset.getFieldValue(assoc.customFieldID).valueArray
                let localizedVals = arrayVals.map((val) => {
                    let ltV = configData.lookupValuesMap?.get(customField.lookupTableID)?.get(val)
                    return t(ltV?.localizedKey, ltV?.name);
                })
                for (let val of localizedVals) {
                    value = value + val + " ";
                }
                break;
        }

        return <p key={index} className="asset-grid-content-p"> {t(customField.customFieldLocalizedKey, customField.customFieldName)} : {value} </p>
    }

    const getNumberOfPages = () => {
        return Math.ceil(totalAssetsCount / 10);
    }

    return (
        <div className="dynamic-asset-list">
            {isProcessing || areAssetsLoading ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Fragment>
                    <Form>
                        <Grid centered>
                            <Pagination
                                boundaryRange={0}
                                siblingRange={1}
                                size='small'
                                activePage={pageNumber}
                                totalPages={getNumberOfPages()}
                                onPageChange={(e, pageInfo: PaginationProps) => setPageNumber(pageInfo.activePage as number)}
                            />
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <br />
                                    <Card.Group centered>
                                        {
                                            assets.map((item, index) => {
                                                return (
                                                    <Card key={index} fluid onClick={() => { props.onItemClicked(item.serialNumber) }} className="asset-card-view">
                                                        <Card.Content className="asset-card-content">
                                                            <Grid>
                                                                <Grid.Row columns={1}>
                                                                    <Grid.Column>
                                                                        {listView.customFieldAssociations.sort((a, b) => a.displayOrder - b.displayOrder).map((assoc, ind) => {
                                                                            return getCardFieldValue(item, assoc, ind);
                                                                        })}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Card.Content>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Fragment>
            )}
        </div>
    );
};
export default DynamicAssetList;
