import { ProcessOrderStatus } from "./ProcessOrderStatus";
export class EYDProcessOrder {
    constructor() {
        this.id = 0;
        this.processOrderNumber = "";
        this.notes = "";
        this.status = ProcessOrderStatus.Active;
        this.createdBy = "";
    }
}
