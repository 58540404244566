import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Loader, Grid, Header, Form, Button, Icon, LabelProps, SemanticShorthandItem } from "semantic-ui-react";
import { DynamicEntityForm, ImageUploadControl, GPSCaptureControl, NotificationService } from "eyam-webui-components";
import { FormActionType, BaseGlobalSettings, DynamicEntityFieldType, DynamicEntityFieldValue, QuantityFieldActionType } from "eyam-webui-models";
import { EYDPhoto, EYDCustomFieldId, EYDAsset, EYDFormViewCustomFieldAssociation } from "eyd.models-ui";
import { AssetFormConfigData, loadAssetData, loadConfigurationData, applyDataDefaults, isLocationValid, checkFieldNullorEmpty, applyReuseValues, loadAssetWithReuseData } from "../Utils/AssetFormUtils";
import { applyLocationID, applyLookupLocation, mergeLocationsDataInConfig } from "../../../../Utility/LocationFieldLookupUtils";
import { applyLookupSKU, applySKU, mergeSKUsDataInConfig } from "../../../../Utility/SKUFieldLookupUtils";

import AssetsService from "../../../../Services/AssetsService";
import AssetPhotosService from "../../../../Services/AssetPhotosService";
import GlobalSettingsService from "../../../../Services/GlobalSettingsService";
import EYDNotificationService from "../../../../Services/EYDNotificationService";

interface DynamicAssetEditProps {
    currentAssetSN: string;
    appViewID: string;
    loadingOverride: boolean;
    reuseEntityVals?: Map<string, DynamicEntityFieldValue>;
    handleAssetSaved: (currentEntityVals?) => void;
    handleAssetDeleted?: () => void;
    handleAssetError: (msg) => void;
}

export const DynamicAssetEdit: React.FunctionComponent<DynamicAssetEditProps> = (props) => {
    const [configData, setConfigData] = useState<AssetFormConfigData>(null);
    const [fieldAssociations, setFieldAssociations] = useState<EYDFormViewCustomFieldAssociation[]>([]);
    const [assetData, setAssetData] = useState<EYDAsset>(null);
    const [assetPhotos, setAssetPhotos] = useState<EYDPhoto[]>([]);
    const [currentEntityValues, setCurrentEntityValues] = useState<Map<string, DynamicEntityFieldValue>>(new Map<string, DynamicEntityFieldValue>());


    const [allowDelete, setAllowDelete] = useState<boolean>(true);
    const [isAssetLoading, setIsAssetLoading] = useState<boolean>(false);
    const [isConfigLoading, setIsConfigLoading] = useState<boolean>(false);
    const [dateFormat, setDateFormat] = useState<string>("DD-MM-YYYY");
    const [imagesMax, setImagesMax] = useState<number>(0);
    const [errorMap, setErrorMap] = useState<Map<string, SemanticShorthandItem<LabelProps>>>(new Map<string, SemanticShorthandItem<LabelProps>>());

    const { t } = useTranslation();
    const mounted = useRef(false);
    const rtParams = useParams<"id">();

    const [widthMax, setWidthMax] = useState<number>(1280);
    const [heightMax, setHeightMax] = useState<number>(720);

    useEffect(() => {

        mounted.current = true;
        setIsAssetLoading(true);

        let load = async () => {

            try {
                if (props.currentAssetSN && props.currentAssetSN !== "") {

                    if (configData !== null && configData.customFieldMap !== null) {
                        let cAsset = await loadAssetData(props.currentAssetSN, configData.usedFieldsList);

                        if (mounted.current) {

                            let setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.DateFormat);
                            if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                                setDateFormat(setting.settingValue.valueText);
                            }
                            setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.ImagesMax);
                            if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                                setImagesMax(setting.settingValue.valueInt);
                            }
                            setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.ImageMaxWidth);
                            if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                                setWidthMax(setting.settingValue.valueInt);
                            }
                            setting = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.ImageMaxHeight);
                            if (setting.settingId !== "00000000-0000-0000-0000-000000000000") {
                                setHeightMax(setting.settingValue.valueInt);
                            }
                            if (cAsset) {

                                // Add the existing photos
                                let cAssetPhotos = await AssetPhotosService.getAssetPhotos(cAsset.assetID);
                                setAssetPhotos(cAssetPhotos);

                                applyDataDefaults(cAsset.fieldValues, configData.fieldAssociations, configData.customFieldMap);

                                // Locations config
                                applyLookupLocation(cAsset.fieldValues, configData.fieldAssociations);

                                //SKUs config
                                applyLookupSKU(cAsset.fieldValues, configData.fieldAssociations);

                                if (configData.appView?.actions?.some(a => a.actionType === FormActionType.AllowsMultipleScans) && props.reuseEntityVals) {
                                    applyReuseValues(cAsset.fieldValues, props.reuseEntityVals, configData.customFieldMap);
                                    setAssetData(loadAssetWithReuseData(cAsset, props.reuseEntityVals, configData.customFieldMap));
                                } else {
                                    setAssetData(cAsset);
                                }

                                setCurrentEntityValues(cAsset.fieldValues);
                                setAllowDelete(true);
                                setFieldAssociations(Object.create(configData.fieldAssociations));

                            } else if (configData.appView?.actions?.some(a => a.actionType === FormActionType.AllowsCreate)) {
                                var assetVals = new Map<string, DynamicEntityFieldValue>();
                                var serDefaultValue = new DynamicEntityFieldValue();
                                serDefaultValue.setObjectValue(props.currentAssetSN, DynamicEntityFieldType.String);
                                assetVals.set(EYDCustomFieldId.SerialNumber, serDefaultValue);

                                var updatedAssocs = Object.create(configData.fieldAssociations);
                                var existSkuAssoc = configData.fieldAssociations.find(a => a.customFieldID === EYDCustomFieldId.SKU)

                                if (!(existSkuAssoc?.isRequired === true)) {
                                    updatedAssocs = updatedAssocs.filter(a => a.customFieldID !== EYDCustomFieldId.SKU);

                                    var skuAssoc: EYDFormViewCustomFieldAssociation = {
                                        id: existSkuAssoc ? existSkuAssoc.id : "",
                                        customFieldID: EYDCustomFieldId.SKU,
                                        isRequired: true,
                                        isReadOnly: false,
                                        formViewID: existSkuAssoc ? existSkuAssoc.formViewID : "",
                                        action: QuantityFieldActionType.CurrentValue,
                                        defaultValue: existSkuAssoc ? existSkuAssoc.defaultValue : "",
                                        overrideWithDefaultValue: existSkuAssoc ? existSkuAssoc.overrideWithDefaultValue : false,
                                        metadata: existSkuAssoc ? existSkuAssoc.metadata : "",
                                        displayOrder: existSkuAssoc ? existSkuAssoc.displayOrder : 0,

                                    }
                                    updatedAssocs.push(skuAssoc);
                                }

                                applyDataDefaults(assetVals, updatedAssocs, configData.customFieldMap);

                                applyLookupLocation(assetVals, updatedAssocs);
                                applyLookupSKU(assetVals, updatedAssocs);

                                if (configData.appView?.actions?.some(a => a.actionType === FormActionType.AllowsMultipleScans) && props.reuseEntityVals) {
                                    applyReuseValues(assetVals, props.reuseEntityVals, configData.customFieldMap);
                                    setAssetData(loadAssetWithReuseData(new EYDAsset(), props.reuseEntityVals, configData.customFieldMap));
                                } else {
                                    setAssetData(new EYDAsset());
                                }

                                setFieldAssociations(updatedAssocs);
                                setCurrentEntityValues(assetVals);
                                setAllowDelete(false);
                            } else {
                                props.handleAssetError(t("PAGE_LABEL-PAGE_ASSET_ERROR_ALERT_NOT_FOUND"));
                            }
                        }
                    }
                }
            } catch (e) {
                handleError(t("GENERIC_ERROR_ALERT-WENT_WRONG"), e);
            } finally {
                setIsAssetLoading(false);
            }
        }

        load();

        return () => {
            mounted.current = false;
        }

    }, [props.currentAssetSN, configData])

    useEffect(() => {
        mounted.current = true;
        setIsConfigLoading(true);

        let load = async () => {
            try {
                let config = await loadConfigurationData(props.appViewID ? props.appViewID : rtParams.id);
                await mergeLocationsDataInConfig(config);
                await mergeSKUsDataInConfig(config);

                if (mounted.current) {

                    setConfigData(config)
                }
            } catch (e) {
                handleError(t("GENERIC_ERROR_ALERT-LOADING_CONFIGURATION_FAILED"), e);
            } finally {
                setIsConfigLoading(false);
            }
        }

        load();

        return () => {
            mounted.current = false;
        }

    }, [])

    const handleError = (errMsg: string, details: any) => {
        if (mounted.current) {
            console.error(details);
            EYDNotificationService.showError(errMsg, details);
        }
    }

    const areRequiredFieldsValid = (entityValues: Map<string, DynamicEntityFieldValue>, field = null) => {

        var map = new Map(errorMap)
        var isValid = true;
        var fieldAssocs = fieldAssociations;

        if (field !== null) {
            fieldAssocs = fieldAssocs.filter(a => a.customFieldID === field);
        }

        for (let assoc of fieldAssocs) {
            if (assoc?.isRequired) {
                if (!entityValues.has(assoc.customFieldID) || checkFieldNullorEmpty(entityValues.get(assoc.customFieldID), configData?.customFieldMap.get(assoc.customFieldID)?.fieldType)) {
                    map.set(assoc.customFieldID, { content: t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_REQUIRED"), pointing: "above" });
                    isValid = false;
                } else {
                    map.set(assoc.customFieldID, null);
                }
            }
        }

        setErrorMap(map);
        return isValid;
    };

    const handleAssetSave = async () => {
        setIsAssetLoading(true);

        try {
            if (areRequiredFieldsValid(currentEntityValues) && assetPhotos.filter(p => !p.isRemoved).length <= imagesMax &&
                (!fieldAssociations.some(a => a.customFieldID === EYDCustomFieldId.Latitude || a.customFieldID === EYDCustomFieldId.Longitude) || isLocationValid(currentEntityValues))) {

                let upstAsset = new EYDAsset();
                let reuseData = new Map(currentEntityValues);
                applyLocationID(currentEntityValues, fieldAssociations);
                applySKU(currentEntityValues, fieldAssociations);

                currentEntityValues.forEach((val, key) => {
                    upstAsset.setFieldValue(key, val);
                });

                var astID = await AssetsService.upsertAssetData(upstAsset);

                for (let cPhoto of assetPhotos) {

                    if (cPhoto.isRemoved && cPhoto.photoID && cPhoto.photoID !== "") {
                        await AssetPhotosService.deletePhotoByID(cPhoto.photoID);
                    }

                    if (!cPhoto.isRemoved && (cPhoto.photoID === null || cPhoto.photoID === "")) {
                        cPhoto.ownerID = astID + "";
                        await AssetPhotosService.upsertAssetPhoto(cPhoto);
                    }
                }

                if (mounted.current) {
                    setIsAssetLoading(false);
                    props.handleAssetSaved(reuseData);
                }
            }
        } catch (e) {
            handleError(t("GENERIC_ERROR_ALERT-SUBMIT_FAILED"), e);
        } finally {
            if (mounted.current) {
                setIsAssetLoading(false);
            }
        }

    }

    const handleImageUploaded = (image: string) => {
        var newPhoto = new EYDPhoto();
        newPhoto.data = image;
        newPhoto.url = null;
        //newPhoto.ownerID = editViewModel.assetID;
        setAssetPhotos([...assetPhotos, newPhoto]);
    };

    const handleImageRemove = (index: number) => {
        var assetPhotosAfterRemove = [...assetPhotos];
        let removedPhoto = {
            ...assetPhotosAfterRemove[index],
            isRemoved: true
        };

        assetPhotosAfterRemove[index] = removedPhoto;
        setAssetPhotos(assetPhotosAfterRemove);
    };

    const handleFieldChange = (field, type, cData) => {
        var newCurrentEntityValues = new Map<string, DynamicEntityFieldValue>(currentEntityValues.set(field, cData))
        setCurrentEntityValues(newCurrentEntityValues);
        areRequiredFieldsValid(newCurrentEntityValues, field);
    }

    const updateCustomFieldCoordValue = (field: string, value: number) => {
        const fieldVal = new DynamicEntityFieldValue();
        fieldVal.setObjectValue(value, DynamicEntityFieldType.Float);
        currentEntityValues.set(field, fieldVal);
        setCurrentEntityValues(new Map<string, DynamicEntityFieldValue>(currentEntityValues));
    };

    const handleAssetDelete = async () => {
        setIsAssetLoading(true);
        try {
            if (assetData) {
                await AssetsService.deleteAsset(assetData.assetID);
            }
            if (mounted.current) {
                setIsAssetLoading(false);
                props.handleAssetDeleted();
            }
        } catch (e) {
            handleError(t("GENERIC_ERROR_ALERT-DELETE_FAILED"), e);
        } finally {
            if (mounted.current) {
                setIsAssetLoading(false);
            }
        }
    }

    const confirmDelete = () => {
        NotificationService.showWarning(t("GENERIC_WARNING_ALERT-DELETE_CONFIRMATION"), t("GENERIC_BUTTON-DELETE"), t("GENERIC_BUTTON-CANCEL"), () => handleAssetDelete());
    }

    return (
        <div className="dynamic-asset-edit common-view">

            {props.loadingOverride || isAssetLoading || isConfigLoading ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Header icon className="common-view__view-header-logo">
                                <Icon name={configData?.appView?.icon as any} />
                            </Header>
                            <DynamicEntityForm
                                customFieldData={configData?.customFieldMap}
                                errorMap={errorMap}
                                viewAssociationData={fieldAssociations?.filter(a => a.customFieldID !== EYDCustomFieldId.Latitude && a.customFieldID !== EYDCustomFieldId.Longitude)}
                                fieldDependencyData={configData?.formFieldDependencies}
                                lookupData={configData?.lookupValuesMap}
                                valueMap={currentEntityValues}
                                onFieldChanged={handleFieldChange}
                                dateFormat={dateFormat}
                            />
                            <br />
                            {fieldAssociations.some(a => a.customFieldID === EYDCustomFieldId.Latitude || a.customFieldID === EYDCustomFieldId.Longitude) &&
                                <GPSCaptureControl
                                    onLatitudeChange={(value) => updateCustomFieldCoordValue(EYDCustomFieldId.Latitude, value)}
                                    onLongitudeChange={(value) => updateCustomFieldCoordValue(EYDCustomFieldId.Longitude, value)}
                                    defaultLatValue={currentEntityValues.get(EYDCustomFieldId.Latitude)?.getObjectValue(DynamicEntityFieldType.Float)}
                                    defaultLongValue={currentEntityValues.get(EYDCustomFieldId.Longitude)?.getObjectValue(DynamicEntityFieldType.Float)}
                                />
                            }
                            <br />
                            <Form>
                                <div className="field">
                                    <label>{t("PAGE_LABEL-PAGE_ASSET_LABEL_IMAGES")}</label>
                                    <ImageUploadControl
                                        displayImg={assetPhotos}
                                        onImageUploaded={handleImageUploaded}
                                        onRemove={handleImageRemove}
                                        uploadBtnLabel={t("PAGE_LABEL-PAGE_ASSET_IMAGE_INPUT")}
                                        error={assetPhotos.filter(p => !p.isRemoved).length > imagesMax}
                                        errorMsg={t("GENERIC_ERROR_ALERT-MAX_IMAGES")}
                                        maxWidth={widthMax}
                                        maxHeight={heightMax}
                                    ></ImageUploadControl>
                                </div>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    {configData?.appView?.actions?.some(a => a.actionType === FormActionType.HasSubmitBtn) &&
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Button fluid primary
                                    content={t("GENERIC_BUTTON-SUBMIT")}
                                    onClick={() => { handleAssetSave(); }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }
                    {configData?.appView?.actions?.some(a => a.actionType === FormActionType.HasDeleteBtn) && allowDelete && props.handleAssetDeleted !== null &&
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Button fluid negative
                                    content={t("GENERIC_BUTTON-DELETE")}
                                    onClick={() => { confirmDelete(); }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }

                </Grid>
            )}
        </div>
    );
};