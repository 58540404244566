﻿import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Label, LabelProps } from "semantic-ui-react";

interface GPSCaptureControlProps {
    defaultLatValue?: number;
    defaultLongValue?: number;
    onLatitudeChange: (lat: number) => any;
    onLongitudeChange: (long: number) => any;
    isReadonly?: boolean;
    hasError?:boolean;
}

export const GPSCaptureControl: React.FunctionComponent<GPSCaptureControlProps> = (props) => {
    const [latitude, setLatitude] = useState<number>(props.defaultLatValue || 0);
    const [longitude, setLongitude] = useState<number>(props.defaultLongValue || 0);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        props.onLatitudeChange(latitude);
    }, [latitude]);

    useEffect(() => {
        props.onLongitudeChange(longitude);
    }, [longitude]);

    const onRefreshCoords = () => {
        navigator.geolocation.getCurrentPosition((location) => {
            const { latitude, longitude } = location.coords;
            setLatitude((latitude <= 90 && latitude >= -90) ? latitude : 0);
            setLongitude((longitude <= 180 && longitude >= -180) ? longitude : 0);
        });
    };

    const validateField = (fieldName: string) => {
        let error: LabelProps = null;
        if (fieldName === "latitude" && (latitude < -90 || latitude > 90)) {
            error = { content: t("PAGE_LABEL-PAGE_ASSET_FIELD_VALIDATION_LATITUDE"), pointing: "above", color:"red", basic:true };
        } else if (fieldName === "longitude" && (longitude < -180 || longitude > 180)) {
            error = { content: t("PAGE_LABEL-PAGE_ASSET_FIELD_VALIDATION_LONGITUDE"), pointing: "above", color:"red", basic:true };
        }

        if(error && !props.isReadonly){
            return <Label {...error} />
        }

    }

    return (
        <Form>
            <Form.Group>
                <Form.Field className="gps-form-field">
                    <label>Latitude</label>
                    <Input type="number" step="any" value={latitude} disabled={props.isReadonly} onChange={(event) => setLatitude(+event.target.value)} />
                     {validateField("latitude")}
                </Form.Field>
                <Form.Field className="gps-form-field">
                    <label>Longitude</label>
                    <Input type="number" step="any" value={longitude} disabled={props.isReadonly} onChange={(event) => setLongitude(+event.target.value)} />
                    {validateField("longitude")}
                </Form.Field>
                {!props.isReadonly && (<div style={{ alignSelf: "end" }} className="field">
                    <Button className="gps-capture-control-button" icon="dot circle outline" onClick={() => onRefreshCoords()} />
                </div>)}
                
            </Form.Group>
        </Form>
    );
};
