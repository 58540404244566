import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Loader } from "semantic-ui-react";
import { MenuTile } from "./Controls/MenuTile";
import { AppRoutes } from "../AppRoutes";
import { useNavigationContext } from "eyam-webui-components";
import { EYDAppView, EYDThemeSetting, EYDViewGroup } from "eyd.models-ui";
import EYDNotificationService from "../Services/EYDNotificationService";
import AppConfigService from "../Services/AppConfigService";
import { AppViewTargetType, AppViewType } from "eyam-webui-models";
import { ThemeStyleType } from "eyam-webui-models/lib/DynamicEntityFormConfig/ThemeStyleType";


const HomeView: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const navContext = useNavigationContext();
    const [viewGroups, setViewGroups] = useState<EYDViewGroup[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [themeSettings, setThemeSettings] = useState<{key: string, iconColor: string, tileColor: string, fontColor: string, backgroundColor: string, cornerRadius: string}[]>([]);

    navContext.setTitle("");

    useEffect(() => {
        let mounted = true;
        setIsProcessing(true);

        const initData = async () => {
            let groups = await AppConfigService.getAllViewGroups(true, true);
            let settings = [];
            for(let group of groups) {
                for(let appView of group.appViews){
                    let allthm = await AppConfigService.getAllThemeSettingsByTargetId(appView.id);

                    if(allthm){

                        let iconColor = allthm.find(t=>t.themeStyleType == ThemeStyleType.IconColor);
                        let tileColor = allthm.find(t=>t.themeStyleType == ThemeStyleType.TileColor);
                        let fontcolor = allthm.find(t=>t.themeStyleType == ThemeStyleType.FontColor);
                        let cornerRadius = allthm.find(t=>t.themeStyleType == ThemeStyleType.CornerRadius);
                        let bgrColor = allthm.find(t=>t.themeStyleType == ThemeStyleType.BackgroundColor);

                        settings.push({
                            key: appView.id, 
                            iconColor: iconColor?.value, 
                            tileColor: tileColor?.value, 
                            fontColor: fontcolor?.value, 
                            backgroundColor: cornerRadius?.value, 
                            cornerRadius: bgrColor?.value});
                    }
                }
            }
            groups.sort((a, b) => a.displayOrder - b.displayOrder);
            return { groups, settings }
        }

        initData().then((result) => {
            if (mounted) {
                setViewGroups(result.groups);
                setThemeSettings(result.settings);
            }
        }).catch((e) => {
            if (mounted) {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-LOADING_FORM_VIEWS_FAILED"), e);
            }

        }).finally(() => {
            setIsProcessing(false);
        })

        return () => {
            mounted = false
        }

    }, []);

    return (
        <div className="home-view">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <>
                    {viewGroups.map((group, groupIndex) => {
                        if (group.appViews?.length > 0) {
                            group.appViews.sort((a, b) => a.displayOrder - b.displayOrder);
                            return (
                                <div key={groupIndex}>
                                    <Divider horizontal key={groupIndex}>{t(group.localizedKey, group.name)}</Divider>

                                    <div className="tile-menu" >
                                        {group.appViews.map((appView, appIndex) => {
                                            if (appView.isEnabled) {
                                                let settings = themeSettings.find(el => el.key === appView.id);
                                                if (appView.viewType === AppViewType.FormView) {  
                                                    if (appView.targetType === AppViewTargetType.AssetView) {
                                                        return <MenuTile key={appIndex} title={t(appView.localizedKey, appView.name)} iconName={appView.icon as any} to={AppRoutes.ASSET_FORM.replace(":id", `${appView.id}`)} iconColor={settings.iconColor} tileColor = {settings.tileColor} fontColor = {settings.fontColor} backgroundColor = {settings.backgroundColor} cornerRadius = {settings.cornerRadius} />;
                                                    } else if (appView.targetType === AppViewTargetType.MaterialView) {
                                                        return <MenuTile key={appIndex} title={t(appView.localizedKey, appView.name)} iconName={appView.icon as any} to={AppRoutes.MATERIAL_FORM.replace(":id", `${appView.id}`)} iconColor={settings.iconColor} tileColor = {settings.tileColor} fontColor = {settings.fontColor} backgroundColor = {settings.backgroundColor} cornerRadius = {settings.cornerRadius} />;
                                                    } else if (appView.targetType === AppViewTargetType.ProcessOrderView) {
                                                        return <MenuTile key={appIndex} title={t(appView.localizedKey, appView.name)} iconName={appView.icon as any} to={AppRoutes.PROCESS_ORDER_FORM.replace(":id", `${appView.id}`)} iconColor={settings.iconColor} tileColor = {settings.tileColor} fontColor = {settings.fontColor} backgroundColor = {settings.backgroundColor} cornerRadius = {settings.cornerRadius} />;
                                                    }
                                                }
                                                if (appView.viewType === AppViewType.ListView) {
                                                    if (appView.targetType === AppViewTargetType.AssetView) {
                                                        return <MenuTile key={appIndex} title={t(appView.localizedKey, appView.name)} iconName={appView.icon as any} to={AppRoutes.ASSET_LIST.replace(":id", `${appView.id}`)} iconColor={settings.iconColor} tileColor = {settings.tileColor} fontColor = {settings.fontColor} backgroundColor = {settings.backgroundColor} cornerRadius = {settings.cornerRadius} />;
                                                    }
                                                }
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    })}


                    {/*<MenuTile title={t("GENERIC_LABEL-ASSET_MAP")} iconName="map marker alternate" to={AppRoutes.ASSET_MAP} />*/}

                    <Divider horizontal>{t("GENERIC_LABEL-ITEM_COUNT")}</Divider>

                    <div className="tile-menu">
                        <MenuTile title={t("GENERIC_LABEL-ITEM_COUNT")} iconName="map" to={AppRoutes.ITEM_COUNT_CONSUME}/>
                    </div>

                    <Divider horizontal>{t("GENERIC_LABEL-APP")}</Divider>

                    <div className="tile-menu">
                        <MenuTile title={t("GENERIC_LABEL-SUPPORT")} iconName="phone" to={AppRoutes.SUPPORT} />
                        <MenuTile title={t("GENERIC_LABEL-SETTINGS")} iconName="cog" to={AppRoutes.SETTINGS} />
                    </div>
                </>
            )}
        </div>
    );
};
export default HomeView;
