import React from 'react';
import { useTranslation } from "react-i18next";
import { Header, Icon} from 'semantic-ui-react';

const NotAllowed: React.FunctionComponent<{}> = (props) => {
    const { t } = useTranslation();
    
    return (
            <div>
                <Header as='h2' icon textAlign='center'>
                    <Icon name='remove' circular />
                <Header.Content>{t("GENERIC_ERROR_ALERT-NOT_ALLOWED")}</Header.Content>
                </Header>
            </div>
        
    );
}

export default NotAllowed