import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { EYDItemMasterDetail } from "eyd.models-ui";
import { EYAMApiResult } from "eyam-webui-models";

class ItemMasterDetailService extends BaseService {
    private readonly BASE_URL: string = "/api/ItemMasterDetail";

    getAllItemMasterDetails(): Promise<EYDItemMasterDetail[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemMasterDetail[]>>(`${this.BASE_URL}/GetAllItemMasterDetails`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getItemMasterDetailBySKU(sku: string): Promise<EYDItemMasterDetail> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemMasterDetail>>(`${this.BASE_URL}/GetItemMasterDetailBySKU/${sku}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getIMDSkuBySerialization(isSerialized : boolean): Promise<EYDItemMasterDetail[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemMasterDetail[]>>(`${this.BASE_URL}/GetAllItemMasterDetails/${isSerialized}`)
                .then((response) => {resolve(response.data.value)})
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new ItemMasterDetailService();