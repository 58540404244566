import {
    DynamicEntityFieldType,
    DynamicEntityFieldValue,
    IntrinsicAssetCustomFieldID,
    IntrinsicInventoryItemCustomFieldID
} from "eyam-webui-models";
import { BusinessLocation, EYDCustomField, EYDFormViewCustomFieldAssociation, EYDLookupTableValue } from "eyd.models-ui";
import BusinessLocationsService from "../Services/BusinessLocationsService";
import EYDNotificationService from "../Services/EYDNotificationService";

const customFieldIDs = [IntrinsicAssetCustomFieldID.LocationID, IntrinsicInventoryItemCustomFieldID.LocationID];
const findFieldIdPredicate = (value) => customFieldIDs.indexOf(value) !== -1;

const fetchLocations = async () => {
    let locations: BusinessLocation[] = [];
    try {
        locations = await BusinessLocationsService.getAllBusinessLocations();
    } catch (error) {
        EYDNotificationService.showError("Fetching Business Locations Failed", error);
    } finally {
        return locations;
    }
};

const loadLocationFieldLookupData = async (customFieldID: string) => {
    const lookupData = new Map<string, EYDLookupTableValue>();
    const locations = await fetchLocations();

    for (let bl of locations) {
        const lookupTableVal: EYDLookupTableValue = {
            ...new EYDLookupTableValue(),
            id: `${bl.id}`,
            name: bl.name,
            localizedKey: bl.name,
            lookupTableID: customFieldID
        };
        lookupData.set(lookupTableVal.id, lookupTableVal);
    }
    return lookupData;
};

const mergeLocationsDataInConfig = async (config: {
    customFieldMap: Map<string, EYDCustomField>;
    fieldAssociations: EYDFormViewCustomFieldAssociation[];
    lookupValuesMap: Map<string, Map<string, EYDLookupTableValue>>;
    usedFieldsList: string[];
}) => {
    const customFieldId = config.usedFieldsList.find(findFieldIdPredicate);
    if (!customFieldId) {
        return config;
    }
    const lookupData = await loadLocationFieldLookupData(customFieldId);
    const customField = config.customFieldMap.get(customFieldId);
    applyLookupCustomfield(customField);
    config.lookupValuesMap.set(customFieldId, lookupData);
};

const applyLookupCustomfield = (customField: EYDCustomField) => {
    customField.customFieldName = "Business Location";
    customField.fieldType = DynamicEntityFieldType.Lookup;
    customField.lookupTableID = customField.customFieldID;
};

const applyLookupLocation = (fieldValues: Map<string, DynamicEntityFieldValue>, fieldAssociations: EYDFormViewCustomFieldAssociation[]) => {
    const fieldValue = new DynamicEntityFieldValue();
    const fieldAssociation = fieldAssociations.find((value) => findFieldIdPredicate(value.customFieldID));
    if (!fieldAssociation) {
        return;
    }
    const value = fieldAssociation.overrideWithDefaultValue
        ? fieldAssociation.defaultValue
        : fieldValues.get(fieldAssociation.customFieldID)?.getObjectValue(DynamicEntityFieldType.Long);
    if (value === null || value === undefined) {
        fieldValue.setObjectValue(null, DynamicEntityFieldType.Lookup);
    } else {
        fieldValue.setObjectValue(`${value}`, DynamicEntityFieldType.Lookup);
    }
    fieldValues.set(fieldAssociation.customFieldID, fieldValue);
};

const applyLocationID = (fieldValues: Map<string, DynamicEntityFieldValue>, fieldAssociations: EYDFormViewCustomFieldAssociation[]) => {
    const newFieldValue = new DynamicEntityFieldValue();
    const fieldAssociation = fieldAssociations.find((value) => findFieldIdPredicate(value.customFieldID));
    if (!fieldAssociation) {
        return;
    }
    let fieldValue = fieldValues.get(fieldAssociation.customFieldID);
    newFieldValue.setObjectValue(+fieldValue.getObjectValue(DynamicEntityFieldType.Lookup), DynamicEntityFieldType.Long);
    fieldValues.set(fieldAssociation.customFieldID, newFieldValue);
};

export { mergeLocationsDataInConfig, applyLookupLocation, applyLocationID };
