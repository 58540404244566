import { IntrinsicInventoryItemCustomFieldID } from "eyam-webui-models";
import { DynamicEntityBase, DynamicEntityFieldValue } from "eyam-webui-models";
export class EYDInventoryItem extends DynamicEntityBase {
    constructor() {
        super();
        this._quantityValues = new Map();
    }
    get itemID() {
        var _a;
        return (_a = this.getFieldValue(IntrinsicInventoryItemCustomFieldID.ItemID)) === null || _a === void 0 ? void 0 : _a.valueLong;
    }
    set itemID(astID) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueLong = astID;
        this.setFieldValue(IntrinsicInventoryItemCustomFieldID.ItemID, cVal);
    }
    get SKU() {
        var _a;
        return (_a = this.getFieldValue(IntrinsicInventoryItemCustomFieldID.SKU)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
    set SKU(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueText = value;
        this.setFieldValue(IntrinsicInventoryItemCustomFieldID.SKU, cVal);
    }
    get name() {
        var _a;
        return (_a = this.getFieldValue(IntrinsicInventoryItemCustomFieldID.Name)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
    set name(value) {
        var cVal = new DynamicEntityFieldValue();
        cVal.valueText = value;
        this.setFieldValue(IntrinsicInventoryItemCustomFieldID.Name, cVal);
    }
    get lastModifiedDate() {
        var _a;
        return (_a = this.getFieldValue(IntrinsicInventoryItemCustomFieldID.LastModifiedDate)) === null || _a === void 0 ? void 0 : _a.valueDateTime;
    }
    get lastModifiedBy() {
        var _a;
        return (_a = this.getFieldValue(IntrinsicInventoryItemCustomFieldID.LastModifiedBy)) === null || _a === void 0 ? void 0 : _a.valueText;
    }
    get quantityValues() {
        return this._quantityValues;
    }
    set quantityValues(quantityValues) {
        this._quantityValues = quantityValues;
    }
    getQuantityValuesMapped() {
        let res = {};
        this._quantityValues.forEach((val, key) => {
            res[key] = val;
        });
        return res;
    }
    getQuantityValues(key) {
        if (this._quantityValues) {
            return this._quantityValues.get(key);
        }
        return null;
    }
    setQuantityValues(key, val) {
        if (this._quantityValues) {
            this._quantityValues.set(key, val);
        }
    }
    getQuantityValue(key, locationId) {
        var _a;
        if (this._quantityValues) {
            return (_a = this._quantityValues.get(key)) === null || _a === void 0 ? void 0 : _a.find(q => q.locationID === locationId);
        }
        return null;
    }
    setQuantityValue(key, locationId, val) {
        if (this._quantityValues) {
            var values = this._quantityValues.get(key);
            if (values.findIndex(obj => obj.locationID == locationId) != -1) {
                values[values.findIndex(obj => obj.locationID == locationId)] = val;
            }
            else {
                values.push(val);
            }
            this._quantityValues.set(key, values);
        }
    }
    addQuantityValue(key, val) {
        if (this._quantityValues) {
            var values = this._quantityValues.get(key);
            if (values === null || values === undefined) {
                values = new Array();
            }
            values.push(val);
            this._quantityValues.set(key, values);
        }
    }
}
