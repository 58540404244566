import Swal from 'sweetalert2';
import '../../styles/shared/sweetalert2.scss'

class EYDNotificationService {

    showSuccess(msg: string): void {

        Swal.fire({
            text: msg,
            icon: 'success',
            buttonsStyling: false,
            customClass: {
                popup: 'sweet_popup',
                confirmButton: 'sweet_confirmbutton',
            }
        });
    }

    showError(msg: string, error?: any): void {

        if (error) console.log(error);
        Swal.fire({
            text: msg,
            icon: 'error',
            buttonsStyling: false,
            customClass: {
                popup: 'sweet_popup',
                confirmButton: 'sweet_confirmbutton',
            }
        });
    }
}
export default new EYDNotificationService();