import React from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import "../../../styles/shared/tile-menu.scss";
import { EYDThemeSetting } from "eyd.models-ui";
import { ThemeStyleType } from "eyam-webui-models/lib/DynamicEntityFormConfig/ThemeStyleType";

export const MenuTile: React.FunctionComponent<{ title: string; iconName: SemanticICONS; to: string; iconColor?: string; tileColor?: string; fontColor?: string; backgroundColor?: string; cornerRadius?: string; }> = (props) => {
    const iconStyle = {
        color: props.iconColor,
    }

    const titleStyle = {
        color: props.fontColor,
    }
    
    const tileCardcontainerStyle = {
        backgroundColor: props.tileColor,
        borderRadius: props.cornerRadius,
    }

    const tileStyle = {
        backgroundColor: props.backgroundColor,
        borderRadius: props.cornerRadius,
        border: 1.5 + "px solid " + props.backgroundColor
    }

    return (
        <div className="tile" style={tileStyle} >
            <NavLink to={props.to}>

                <div className="tile__card-container" style={tileCardcontainerStyle}>
                    <div className="tile__card-icon" >
                        <Icon name={props.iconName} style={iconStyle} />
                    </div>
                    <div className="tile__card-title" style={titleStyle}>
                        {props.title}
                    </div>
                </div>
            </NavLink>
        </div>
    );
};