import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dropdown, DropdownProps, Form, Grid, Header, Icon, Loader } from "semantic-ui-react";
import { isAllowed, NavigationObserver, useAuthContext, useNavigationContext, useRuntimeContext } from "eyam-webui-components";
import { LocalizationLanguage } from "eyam-webui-models";
import LocalizationService from "../../../Services/LocalizationService";
import { useNavigate } from "react-router-dom";
import UtilityService from "../../../Services/UtilityService";
import EYDNotificationService from "../../../Services/EYDNotificationService";
import { AuthAreas } from "../../../Auth/AuthAreas";


const SettingsView: React.FunctionComponent<{}> = () => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [languages, setLanguages] = useState<LocalizationLanguage[]>();
    const runtimeContext = useRuntimeContext();
    const navContext = useNavigationContext();
    const authContext = useAuthContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        initValues();
        navContext.navigationSubject.attach(() => { onBackButtonClick() });
        navContext.setTitle(t("GENERIC_LABEL-SETTINGS"));
    }, []);

    let initValues = async () => {
        try {
            setIsProcessing(true);
            const languages = await LocalizationService.getAllLanguages();
            setIsProcessing(false);
            setLanguages(languages);
        } catch (e) {
            setIsProcessing(false);
        }
    };

    const onBackButtonClick: NavigationObserver = () => {
        navigate(-1);
        navContext.navigationSubject.detach();
    }

    const handleLanguageChange = (language: DropdownProps) => {
        const { shortName, name } = languages?.find((l) => l.shortName === language.value);

        runtimeContext.setLanguage(shortName);
    };

    const handleFlushCacheClick = async () => {
        setIsProcessing(true);
        var res = await UtilityService.flushCache();
        setIsProcessing(false);
        EYDNotificationService.showSuccess(t("PAGE_LABEL-PAGE_SETTINGS_CACHE_FLUSH_SUCCESS"));
    }

    return (
        <div className="settings-view">
            {isProcessing ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Grid columns={1} stackable textAlign='center'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <br />
                            <Header icon>
                                <Icon name='setting' />
                                {/* {t("GENERIC_LABEL-SETTINGS")} */}
                            </Header>
                            <Form>
                                <Form.Field>
                                    <label>{t("PAGE_LABEL-PAGE_SETTINGS_LABEL_LANGUAGE")}</label>
                                    <Dropdown
                                        fluid
                                        options={languages?.map((language, index) => ({ key: index, value: language.shortName, text: language.name })) || []}
                                        search
                                        selection
                                        onChange={(_, data) => { handleLanguageChange(data) }}
                                        value={runtimeContext.language || "en"}
                                        placeholder={t("PAGE_LABEL-PAGE_SETTINGS_PLACEHOLDER_LANGUAGE")}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    {isAllowed(authContext?.userProfile?.roles ? authContext?.userProfile?.roles : [], authContext.areaAccesRules, AuthAreas.ADMIN_AREA) &&
                        <Grid.Row columns={2} verticalAlign='middle' >
                            <Grid.Column>
                                <Button fluid primary
                                    content={t("PAGE_LABEL-PAGE_SETTINGS_BUTTON_FLUSH_CACHE")} onClick={() => handleFlushCacheClick()} />
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            )}
        </div>
    );
};
export default SettingsView;