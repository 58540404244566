import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available
        backend: {
            loadPath: "/api/Localization/GetLocalizationValuesJSON?language={{lng}}"
        },
        keySeparator: "-" // we do not use keys in form messages.welcome
    });

export default i18n;
