import { CustomFieldBase, DEFViewCustomFieldAssociationBase, DynamicEntityFieldType, DynamicEntityFieldValue, DynamicFieldDependecyType, DynamicFieldDependencyBase, DynamicFieldLookupDependencyBase, QuantityFieldActionType, IntrinsicAssetCustomFieldID, IntrinsicInventoryItemCustomFieldID, LookupTableValueBase } from "eyam-webui-models";
import React, { useEffect, useState } from "react";
import { DateInput, DateInputProps } from 'semantic-ui-calendar-react';
import moment from "moment";
import { Dropdown, DropdownItemProps, DropdownProps, FormFieldProps, Input, InputProps, LabelProps, TextArea, TextAreaProps,SemanticShorthandItem } from "semantic-ui-react";
import { isNullOrUndefined } from "util";
import { DynamicDropdown, DynamicDropdownProps } from "../DynamicDropdown/DynamicDropdown";
import { DynamicForm } from "../DynamicForm/DynamicForm";
import { FormFieldGroup } from "../DynamicForm/FormFieldGroup";
import { FieldDependencyGraph } from "./FieldDependencyGraph";


interface DynamicEntityFormProps<
    CFA extends DEFViewCustomFieldAssociationBase,
    LKPV extends LookupTableValueBase,
    CF extends CustomFieldBase,
    FD extends DynamicFieldDependencyBase<FDLKV>,
    FDLKV extends DynamicFieldLookupDependencyBase
    > {
    customFieldData: Map<string, CF>;
    lookupData: Map<string, Map<string, LKPV>>;
    viewAssociationData: CFA[];
    fieldDependencyData: FD[];
    errorMap: Map<string, SemanticShorthandItem<LabelProps>>;
    valueMap: Map<string, DynamicEntityFieldValue>;
    onFieldChanged?: (fieldID: string, fieldType: DynamicEntityFieldType, value: DynamicEntityFieldValue) => void;
    translate?: (locValue: string) => string;
    metadataFieldTransform?: (fieldMetaData: string, associationMetaData: string, currentConfig: FormFieldProps) => FormFieldProps;
    metadataLkpValueTransform?: (lkpValue: LKPV, associationMetaData: string, currentConfig: DropdownItemProps) => DropdownItemProps;
    dateFormat?: string;

}


export const DynamicEntityForm = <
    CFA extends DEFViewCustomFieldAssociationBase,
    LKPV extends LookupTableValueBase,
    CF extends CustomFieldBase,
    FD extends DynamicFieldDependencyBase<FDLKV>,
    FDLKV extends DynamicFieldLookupDependencyBase,>(props: DynamicEntityFormProps<CFA, LKPV, CF, FD, FDLKV>) => {

    //CustomfieldID => DependsOnCustomFieldID => DependsOnLookupValueID => AllowedCustomFieldLookupValues
    const [filterCascadeDepMatrix, setFilterCascadeDepMatrix] = useState<Map<string, Map<string, Map<string, string[]>>>>(new Map<string, Map<string, Map<string, string[]>>>());
    const [dropdownLookupValues, setDropdownLookupValues] = useState<Map<string, { dropdownProps: DropdownItemProps, lookupData: LKPV }[]>>(new Map<string, { dropdownProps: DropdownItemProps, lookupData: LKPV }[]>());

    const [depGraph, setDepGraph] = useState<FieldDependencyGraph>();

    const handleFieldChange = (fieldID: string, type: DynamicEntityFieldType, value: any): void => {

        if (props.onFieldChanged) {
            var newVal = new DynamicEntityFieldValue();
            newVal.setObjectValue(value, type, props.dateFormat != null ? props.dateFormat : "DD-MM-YYYY");
            props.onFieldChanged(fieldID, type, newVal);

            depGraph.triggerEffectsForField(fieldID, (crtfield, parentField, currentDependencies) => {

                console.debug(`DynamicEntityForm: triggerEffectsForField=> Field ${crtfield} | Name ${props.customFieldData?.has(crtfield) ? props.customFieldData.get(crtfield).customFieldName : "unknown"}`);

                if (currentDependencies.some(dp => dp.dependencyType == DynamicFieldDependecyType.FilterCascade)) {
                    if (props.valueMap.has(crtfield) && props.valueMap.get(crtfield).getObjectValue != null) {

                        var nullVal = new DynamicEntityFieldValue();
                        nullVal.setObjectValue(value, type);

                        props.onFieldChanged(crtfield, props.customFieldData.get(crtfield).fieldType, nullVal);
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });
        }
    }

    useEffect(() => {
        if (props.lookupData) {
            console.debug("DynamicEntityForm: Loading Lookup values");
            let result: Map<string, { dropdownProps: DropdownItemProps, lookupData: LKPV }[]> = new Map<string, { dropdownProps: DropdownItemProps, lookupData: LKPV }[]>();

            props.lookupData.forEach((lkpData, key, map) => {
                let lkpTransfData: { dropdownProps: DropdownItemProps, lookupData: LKPV }[] = [];
                lkpData.forEach((lkpVal) => {

                    let cDropDownValue: DropdownItemProps = { key: lkpVal.id, value: lkpVal.id };
                    cDropDownValue.text = props.translate ? props.translate(lkpVal.localizedKey) : lkpVal.name;

                    lkpTransfData.push({
                        dropdownProps: cDropDownValue,
                        lookupData: lkpVal
                    });

                    lkpTransfData.sort((a, b) => (a.dropdownProps.text > b.dropdownProps.text) ? 1 : -1);
                });
                result.set(key, lkpTransfData)

            });
            setDropdownLookupValues(result);
        }

    }, [props.lookupData]);

    useEffect(() => {
        //aggregate dependencies for fast access

        if (props.fieldDependencyData) {
            console.debug("DynamicEntityForm: aggregate dependency values");

            let depMatrix = new Map<string, Map<string, Map<string, string[]>>>();

            if (props.fieldDependencyData) {

                let depGrps = new Map<string, FD[]>();

                for (let dp of props.fieldDependencyData) {
                    if (dp.dependencyType == DynamicFieldDependecyType.FilterCascade) {
                        if (!depGrps.has(dp.customFieldID)) {
                            depGrps.set(dp.customFieldID, []);
                        }
                        depGrps.get(dp.customFieldID).push(dp);
                    }
                }

                depGrps.forEach((group, key, map) => {

                    let dependencyFilterMap = new Map<string, Map<string, string[]>>();

                    for (let dep of group) {

                        let dependencyGroups = new Map<string, string[]>();

                        for (let lkpDep of dep.lookupDependencies) {

                            if (!dependencyGroups.has(lkpDep.dependsOnLookupValueID)) {
                                dependencyGroups.set(lkpDep.dependsOnLookupValueID, []);
                            }

                            dependencyGroups.get(lkpDep.dependsOnLookupValueID).push(lkpDep.lookupValueID);
                        }
                        dependencyFilterMap.set(dep.dependsOnCustomFieldID, dependencyGroups);
                    }

                    depMatrix.set(key, dependencyFilterMap)
                });
            }


            setFilterCascadeDepMatrix(depMatrix)

            let grp = new FieldDependencyGraph();
            grp.initialize(props.fieldDependencyData);

            setDepGraph(grp);
        }
    }, [props.fieldDependencyData]);


    const getFormGroups = (): FormFieldGroup[] => {

        const groups: FormFieldGroup[] = [
            {
                groupProps:
                {
                    grouped: false,
                    widths: 'equal',
                },
                formFields: []
            }
        ];


        if (props.viewAssociationData) {

            var sortedAsocs = props.viewAssociationData.sort((a, b) => a.displayOrder - b.displayOrder);

            for (let cAsoc of sortedAsocs) {

                let cfData = props.customFieldData.get(cAsoc.customFieldID);
                if (!cfData) {
                    console.debug("DynamicEntityForm: Custom field data for assoc not found: " + cAsoc.customFieldID);
                    continue;
                }

                let fieldConfig: FormFieldProps = {};

                if (props.errorMap.has(cfData.customFieldID)) {
                    fieldConfig.error =  props.errorMap.get(cfData.customFieldID);
                } else {
                    fieldConfig.error = false; 
                }

                fieldConfig.label = (props.translate ? props.translate(cfData.customFieldLocalizedKey) : cfData.customFieldName) + 
                    (cAsoc.action === QuantityFieldActionType.UseIncrement ? " (+)" : cAsoc.action === QuantityFieldActionType.UseDecrement ? " (-)" : "" );
                fieldConfig.disabled = cAsoc.isReadOnly;
                fieldConfig.required = cAsoc.isRequired;

                let fldValue = null;

                if (props.valueMap.has(cfData.customFieldID) && props.valueMap.get(cfData.customFieldID)) {
                    fldValue = props.valueMap.get(cfData.customFieldID).getObjectValue(cfData.fieldType);
                }
                
                if(cfData.fieldType == DynamicEntityFieldType.Lookup && 
                    (cfData.customFieldID == IntrinsicAssetCustomFieldID.LocationID || 
                    cfData.customFieldID == IntrinsicInventoryItemCustomFieldID.LocationID || 
                    cfData.customFieldID == IntrinsicAssetCustomFieldID.SKU || 
                    cfData.customFieldID == IntrinsicInventoryItemCustomFieldID.SKU)){
                        fieldConfig.control =  DynamicDropdown;
                        let lkpCfg: DynamicDropdownProps = {
                            onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                            options: getDropDownItemsFromLookup(cfData.customFieldID, cAsoc.metadata),
                            fluid: true,
                            selection: true,
                            value: fldValue ? fldValue : "",
                            search: true,
                            maxDisplayed: 7,

                        };
                        fieldConfig = { ...fieldConfig, ...lkpCfg };
                        
                    }                   
                    else{
                        switch (cfData.fieldType) {
                            case DynamicEntityFieldType.String:
                                fieldConfig.control = Input;
                                let inputCfg: InputProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    value: fldValue ? fldValue : ""
                                };
                                fieldConfig = { ...fieldConfig, ...inputCfg };
                                break;
        
                            case DynamicEntityFieldType.Integer:
                            case DynamicEntityFieldType.Float:
                            case DynamicEntityFieldType.Long:
                                fieldConfig.control = Input;
                                let intInputCfg: InputProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    type: "number",
                                    value: fldValue ? fldValue : ""
                                };
                                fieldConfig = { ...fieldConfig, ...intInputCfg };
                                break;
        
                            case DynamicEntityFieldType.Boolean:
                                fieldConfig.control =  Dropdown;
                                let boolLkpCfg: DropdownProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    fluid: true,
                                    selection: true,
                                    options: [{ key: 1, value: true, text: props.translate ? props.translate("GENERIC-LABEL-YES") : "Yes" }, { key: 2, value: false, text: (props.translate ? props.translate("GENERIC-LABEL-NO") : "No") }],
                                    value: fldValue ? fldValue : "",
                                    search: true
                                };
                                fieldConfig = { ...fieldConfig, ...boolLkpCfg };
                                break;
        
                            case DynamicEntityFieldType.Date:
                                fieldConfig.control = DateInput;
                                let datePkrProps: DateInputProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    error: props.errorMap.has(cfData.customFieldID) ? props.errorMap.get(cfData.customFieldID) : !checkDateFieldFormat(fldValue as string),
                                    readOnly: cAsoc.isReadOnly,
                                    inline: false,
                                    value: fldValue ? fldValue : "",
                                    dateFormat: props.dateFormat != null ? props.dateFormat : "DD-MM-YYYY"
                                };
        
                                fieldConfig = { ...fieldConfig, ...datePkrProps };
        
                                break;
        
                            case DynamicEntityFieldType.Lookup:
                                fieldConfig.control = Dropdown;
                                let lkpCfg: DropdownProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    options: getDropDownItemsFromLookup(cfData.customFieldID, cAsoc.metadata),
                                    fluid: true,
                                    selection: true,
                                    value: fldValue ? fldValue : "",
                                    search: true
                                };
                                fieldConfig = { ...fieldConfig, ...lkpCfg };
                                break;
        
                            case DynamicEntityFieldType.LongString:
                                fieldConfig.control = TextArea;
                                let txtAreaCfg: TextAreaProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    value: fldValue ? fldValue : ""
                                };
                                fieldConfig = { ...fieldConfig, ...txtAreaCfg };
                                break;
        
                            case DynamicEntityFieldType.Array:
                                fieldConfig.control = Dropdown;
                                let multiLkpCfg: DropdownProps = {
                                    onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                    search: true,
                                    multiple: true,
                                    options: getDropDownItemsFromLookup(cfData.customFieldID, cAsoc.metadata),
                                    selection: true,
                                    value: fldValue ? fldValue : []
                                };
        
                                fieldConfig = { ...fieldConfig, ...multiLkpCfg };
                                break;
                        }
                    }


                if (props.metadataFieldTransform) {
                    props.metadataFieldTransform(cfData.metadata, cAsoc.metadata, fieldConfig);
                }
                groups[0].formFields.push(fieldConfig);
            }

        }
        return groups;
    };

    const checkDateFieldFormat = (dateValue: string) => {

        let dateFormat = props.dateFormat != null ? props.dateFormat : "DD-MM-YYYY"

        if (dateValue?.length > 0 && !moment(dateValue, dateFormat, true).isValid()) {
            return false;
        }
        return true;
    }

    const getDropDownItemsFromLookup = (fieldID: string, associationMetaData: string): DropdownItemProps[] => {

        //TODO: maybe put these values on soem state obejct so they don;t get affect performance

        let result: DropdownItemProps[] = [];

        if (!props.customFieldData.has(fieldID)) {
            console.debug("DynamicEntityForm: field not defined: " + fieldID);
            return [];
        }

        let cfData = props.customFieldData.get(fieldID);

        if (!props.lookupData.has(cfData.lookupTableID)) {
            console.debug("DynamicEntityForm: lookup data not defined: " + cfData.lookupTableID);
            return [];
        }

        if (!dropdownLookupValues.has(cfData.lookupTableID)) {
            console.debug("DynamicEntityForm: lookup data not transformed: " + cfData.lookupTableID);
            return [];
        }

        dropdownLookupValues.get(cfData.lookupTableID).forEach((lkpVal, lkpTblID) => {

            let cDropDownValue: DropdownItemProps = { key: lkpVal.dropdownProps.key, value: lkpVal.dropdownProps.value, text: lkpVal.dropdownProps.text };

            if (props.metadataLkpValueTransform) {
                props.metadataLkpValueTransform(lkpVal.lookupData, associationMetaData, cDropDownValue);
            }


            //props.valueMap.has(cfData.customFieldID) ? props.valueMap.get(cfData.customFieldID).getObjectValue(cfData.fieldType) : []

            let lkpValAvailable = true;

            //CustomfieldID => DependsOnCustomFieldID => DependsOnLookupValueID => AllowedCustomFieldLookupValues
            if (filterCascadeDepMatrix.has(fieldID)) {
                let crtFilters = filterCascadeDepMatrix.get(fieldID);

                let allowedValues: string[] = null;

                for (let [depCfID, depFilter] of crtFilters) {
                    //if no vale is selected for depending field we can;t have valeu for the current one
                    if (!props.valueMap.has(depCfID)) {
                        lkpValAvailable = false;
                        break;
                    }

                    let compValues = [];
                    //let cValue = props.valueMap.get(depCfID).getObjectValue(cfData.fieldType);

                    let cValue = props.valueMap.get(depCfID).getObjectValue(props.customFieldData.get(depCfID).fieldType);

                    if (cValue) {
                        if (props.customFieldData.get(depCfID).fieldType == DynamicEntityFieldType.Array) {
                                compValues = [...cValue];
                        } else if (cValue != "") {
                                compValues.push(cValue);
                        }
                    }


                    let allowedValsForDepLkp = [];

                    for (let cDepLkpVal of compValues) {
                        if (depFilter.has(cDepLkpVal)) {
                            depFilter.get(cDepLkpVal).forEach(cDepVal => {
                                if (!allowedValsForDepLkp.some(v => v == cDepVal)) {
                                    allowedValsForDepLkp.push(cDepVal);
                                }
                            });
                        }
                    }

                    if (allowedValues == null) {
                        allowedValues = allowedValsForDepLkp;
                    } else {
                        allowedValues = allowedValues.filter(x => allowedValsForDepLkp.includes(x));
                    }

                }

                if (allowedValues == null || !allowedValues.includes(lkpVal.lookupData.id)) {
                    lkpValAvailable = false;
                }
            }

            if (lkpValAvailable) {
                result.push(cDropDownValue);
            }

        });

        return result;
    }

    return (

        <div className="dynamic-form-container">
            <DynamicForm groups={getFormGroups()} />
        </div>
    );
}
