import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, Form, Grid, GridRow, Header, Icon, Input, Loader, Modal, LabelProps, Button, SemanticShorthandItem } from "semantic-ui-react";
import { DynamicModalForm, DynamicModalFormAction, FormFieldGroup, NotificationService } from "eyam-webui-components";
import { FormActionType, FormViewType, DynamicEntityFieldValue, FieldRole, QuantityFieldActionType, FieldTargetType } from "eyam-webui-models";
import { EYDAppView, EYDCustomField, EYDInventoryItem, EYDProcessOrder, EYDProcessOrderItem, EYDQuantityFieldValue, ProcessOrderStatus } from "eyd.models-ui";
import { ScannerEntry } from "../../../Controls/ScannerEntry";
import { InventoryFormConfigData, loadConfigurationData } from "../../Inventory/Utils/InventoryFormUtils";

import EYDNotificationService from "../../../../Services/EYDNotificationService";
import MaterialInventoryService from "../../../../Services/MaterialInventoryService";
import ProcessOrdersService from "../../../../Services/ProcessOrdersService";

import "../../../../../styles/shared/common-view.scss"
import AppConfigService from "../../../../Services/AppConfigService";
import CustomFieldsService from "../../../../Services/CustomFieldsService";

interface DynamicProcessOrderEditProps {
    currentProcessOrderNumber: string;
    locationId: number;
    handleProcessOrderSaved: () => void;
    handleProcessOrderError: (msg, returnToScan) => void;
    handleAssetScan: (processOrderItem: EYDProcessOrderItem, serialNumber: string) => void;
    handleMaterialSelect: (processOrderItem: EYDProcessOrderItem, serialNumber: string) => void;
}

enum ModalScanState {
    ScanSN,
    ScanSKU
}

export const DynamicProcessOrderEdit: React.FunctionComponent<DynamicProcessOrderEditProps> = (props) => {

    const [appView, setAppView] = useState<EYDAppView>(null);
    const [customFields, setCustomFields] = useState<EYDCustomField[]>([])
    const [processOrderData, setProcessOrderData] = useState<EYDProcessOrder>(null);
    const [processOrderItems, setProcessOrderItems] = useState<EYDProcessOrderItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<EYDProcessOrderItem>(null);
    const [scannedSN, setScannedSN] = useState<string>("");

    const [isProcessOrdLoading, setIsProcessOrdLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [modalScanState, setModalScanState] = useState<ModalScanState>(ModalScanState.ScanSKU);
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [filteredItems, setFilteredItems] = useState<EYDProcessOrderItem[]>([]);


    const { t } = useTranslation();
    const mounted = useRef(false);
    const rtParams = useParams<"id">();

    useEffect(() => {
        mounted.current = true;
        setIsProcessOrdLoading(true);

        const initValues = async () => {
            try {
                if (props.currentProcessOrderNumber && props.currentProcessOrderNumber !== "") {
                    let appV = await AppConfigService.getAppViewByID(rtParams.id);
                    let cfs = await CustomFieldsService.getCustomFieldsByType(FieldTargetType.Inventory);
                    let resp = await ProcessOrdersService.getProcessOrderWithItems(props.currentProcessOrderNumber);

                    if (mounted.current) {
                        setCustomFields(cfs.filter(cf => cf.fieldRole === FieldRole.Quantity));
                        setAppView(appV);
                        if (resp !== null) {
                            setProcessOrderData(resp.processOrder);
                            setProcessOrderItems(resp.processOrderItems);
                        } else {
                            props.handleProcessOrderError(t("PAGE_LABEL-PAGE_PROCESS_ORDER_ERROR_ALERT_NOT_FOUND"), true);
                        }
                    }
                }
            }
            catch (err) {
                props.handleProcessOrderError(t("GENERIC_ERROR_ALERT-WENT_WRONG"), true);
            }
            finally {
                setIsProcessOrdLoading(false);
            }

        }

        initValues();

        return () => {
            mounted.current = false;
        }
    }, [props.currentProcessOrderNumber]);



    useEffect(() => {
        let aux = processOrderItems.map(item => ({ ...item }));
        aux.sort((a, b) => {
            let isAScanned = (a.serialNumber !== null && a.serialNumber !== "") || a.operatedQuantities.length > 0;
            let isBScanned = (b.serialNumber !== null && b.serialNumber !== "") || b.operatedQuantities.length > 0;
            if (isAScanned && !isBScanned) {
                return 1;
            }
            if (!isAScanned && isBScanned) {
                return -1;
            }
            return 0;
        })

        setFilteredItems(aux);

    }, [processOrderItems])



    const handleModalSubmit = async () => {

        if (scannedSN !== null && scannedSN.length > 0) {
            let newItem = { ...selectedItem, serialNumber: scannedSN };
            setProcessOrderItems(processOrderItems.map(item => item.id === newItem.id ? newItem : item));
            props.handleAssetScan(selectedItem, scannedSN);
            setSubmitError(false);
        } else {
            setSubmitError(true);
        }

    }

    const isSNValid = (sn: string): boolean => {

        if (submitError && (sn === null || sn.length < 1)) {
            return false;
        }

        return true;
    }


    const getFormActions = (): DynamicModalFormAction[] => {
        let actions: DynamicModalFormAction[] = [
            {
                key: "ModalSubmitButton",
                content: t("GENERIC_BUTTON-SUBMIT"),
                onClick: handleModalSubmit,
                style: { marginLeft: "auto" },
                primary: true

            },
            {
                key: "ModalCancelButton",
                content: t("GENERIC_BUTTON-CANCEL"),
                onClick: () => {
                    setOpen(false); setSubmitError(false);
                }
            }
        ];

        return actions;
    }

    const getFormGroups = (): FormFieldGroup[] => {
        let groups: FormFieldGroup[] = [
            {
                tabPane: { content: "Tab 1" },
                formFields: [{
                    label: t("CF_NAME-SERIAL_NUMBER"),
                    control: ScannerEntry,
                    inputValue: scannedSN,
                    inputPlaceholder: t("PAGE_LABEL-PAGE_ASSET_PLACEHOLDER_SCAN_ASSET"),
                    invalidSN: !isSNValid(scannedSN),
                    errorMsg: t("PAGE_LABEL-PAGE_PROCESS_ORDER_SERIAL_NUMBER_REQUIRED"),
                    onCodeScanned: (code: string) => {
                        setScannedSN(code);
                    },
                }
                ]
            }];

        return groups;
    }


    const renderProcessOrderInfo = () => {
        return (
            <Modal open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
                <Modal.Header>{t("PAGE_LABEL-PAGE_PROCESS_ORDER_ORDER_INFO")}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid>
                            <GridRow columns={2}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label >{t("PAGE_LABEL-PAGE_PROCESS_ORDER_LABEL_PROCESS_ORDER_NUMBER")}</label>
                                        <p >{processOrderData?.processOrderNumber}</p>
                                    </Form.Field>
                                </Grid.Column>
                            </GridRow>
                            <GridRow columns={1}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label >{t("PAGE_LABEL-PAGE_PROCESS_ORDER_LABEL_NOTES")}</label>
                                        <p>{processOrderData?.notes}</p>
                                    </Form.Field>
                                </Grid.Column>
                            </GridRow>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        )
    }

    const onItemClick = (item: EYDProcessOrderItem) => {

        if (item.operatedQuantities.length === 0 && (item.serialNumber === null || item.serialNumber === "")) {
            if (item.isSerialized === false) {

                props.handleMaterialSelect(item, item.itemSKU);

            } else {
                setSelectedItem(item);
                setModalScanState(ModalScanState.ScanSN);
                setOpen(true);
            }

        } else {
            EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-ITEM_ALREADY_SCANNED"));
        }

    }

    const closeProcessOrder = async () => {
        setIsProcessOrdLoading(true);
        try {

            let isClosed: boolean = true;

            processOrderItems.forEach(item => {
                let isItemScanned = (item.serialNumber !== null && item.serialNumber !== "") || item.operatedQuantities.length > 0;
                isClosed = isClosed && isItemScanned;

            });

            if (isClosed) {
                let aux = { ...processOrderData };
                aux.status = ProcessOrderStatus.Closed;
                let resp = await ProcessOrdersService.updateProcessOrder(aux);
                props.handleProcessOrderSaved();
            } else {
                EYDNotificationService.showError(t("GENERIC_ERROR_ALERT-CLOSE-PROCESS-ORDER"));
            }
        } catch (e) {
            EYDNotificationService.showError(e);
        } finally {
            setIsProcessOrdLoading(false);
        }
    }

    const getSign = (action: QuantityFieldActionType) => {
        switch (action) {
            case QuantityFieldActionType.CurrentValue: return "=";
            case QuantityFieldActionType.UseIncrement: return "+";
            case QuantityFieldActionType.UseDecrement: return "-";
        }
    }



    return (
        <div className="dynamic-process-order-edit common-view">
            {isProcessOrdLoading ? (
                <Loader content={t("GENERIC-LOADING_ALERT")} inline="centered" active />
            ) : (
                <Fragment>

                    <DynamicModalForm
                        onOpenHandler={() => { setOpen(true); }}
                        onCloseHandler={() => setOpen(false)}
                        open={open}
                        title={modalScanState === ModalScanState.ScanSKU ? t("PAGE_LABEL-PAGE_PROCESS_ORDER_LABEL_QUANTITY_USED") : t("PAGE_LABEL-PAGE_PROCESS_ORDER_SCAN_SN")}
                        modalFormGroups={getFormGroups()}
                        formActions={getFormActions()}
                        isProcessing={isProcessOrdLoading}
                    />

                    <Form>
                        <Grid centered>
                            <GridRow columns={2}>
                                <Grid.Column width={5}>
                                    <Icon name='info circle' size='huge' onClick={() => setInfoModalOpen(true)} />
                                </Grid.Column>
                                {appView?.actions?.some(a => a.actionType === FormActionType.HasCloseBtn) && processOrderData.status !== ProcessOrderStatus.Closed &&
                                    <Grid.Column verticalAlign="middle">
                                        <Button name='info circle' onClick={() => closeProcessOrder()} floated="right">
                                            {t("PAGE_LABEL-PAGE_PROCESS_ORDER_FINISH_PROCESS_ORDER")}
                                        </Button>
                                    </Grid.Column>
                                }
                            </GridRow>


                            {renderProcessOrderInfo()}

                            <GridRow columns={1}>
                                <Grid.Column>

                                    <Card.Group centered>
                                        {
                                            filteredItems.map((item, index) => {
                                                let scanned = item.serialNumber || item.operatedQuantities.length > 0;

                                                return (
                                                    <Card key={index} fluid link onClick={() => { onItemClick(item) }} className="card-view">
                                                        <Card.Content>
                                                            <Card.Meta>
                                                                <Grid>
                                                                    <Grid.Row columns={2}>
                                                                        <Grid.Column width={10}>
                                                                            <Header>{item.processOrderItemName}</Header>

                                                                            <div className="card-view">
                                                                                <p> {t('PAGE_LABEL-PAGE_PROCESS_ORDER_LABEL_SKU')} : {item.itemSKU} </p>
                                                                                {
                                                                                    !item.isSerialized ? (
                                                                                        item.operatedQuantities?.map((quantity, index) => {
                                                                                            let cf = customFields.find(cf => cf.customFieldID === quantity.customFieldID);
                                                                                            return <p key={index}> {t(cf?.customFieldLocalizedKey, cf?.customFieldName)} : {getSign(quantity.action)}{quantity.valueDouble} </p>
                                                                                        })
                                                                                    ) : (
                                                                                        <p> {t('CF_NAME-SERIAL_NUMBER')} : {item.serialNumber} </p>

                                                                                    )
                                                                                }

                                                                            </div>



                                                                        </Grid.Column>

                                                                        <Grid.Column width={5} verticalAlign="middle" textAlign="center">
                                                                            <Icon className="card-icon" size="huge" name={scanned ? "check" : "barcode"} color={scanned ? "green" : "black"} />
                                                                        </Grid.Column>

                                                                    </Grid.Row>

                                                                </Grid>
                                                            </Card.Meta>
                                                        </Card.Content>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Card.Group>
                                </Grid.Column>
                            </GridRow>
                        </Grid>
                    </Form>
                </Fragment>
            )}

        </div>
    );

}