import { BaseService } from "./BaseService";
import { EYDFormView, EYDFormViewCustomFieldAssociation, EYDCustomFieldDependency, EYDFieldLookupDependency, EYDAppView, EYDViewGroup, EYDListView, EYDThemeSetting } from "eyd.models-ui";
import AppHTTPClient from "./AppHTTPClient";
import { EYAMApiResult } from "eyam-webui-models";

class AppConfigService extends BaseService {
    private readonly BASE_URL: string = "/api/AppConfig";

    getAllViewGroups(includeAppViews: boolean, onlyIncludeEnabled: boolean): Promise<EYDViewGroup[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDViewGroup[]>>(`${this.BASE_URL}/GetAllViewGroups/${includeAppViews}/${onlyIncludeEnabled}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllAppViews(): Promise<EYDAppView[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDAppView[]>>(`${this.BASE_URL}/GetAllAppViews`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAppViewByID(appViewId: string): Promise<EYDAppView> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDAppView>>(`${this.BASE_URL}/GetAppViewByID/${appViewId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllFormViews(): Promise<EYDFormView[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFormView[]>>(`${this.BASE_URL}/GetAllFormViews`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFormViewsByAppViewID(appViewId: string): Promise<EYDFormView[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFormView[]>>(`${this.BASE_URL}/GetFormViewsByAppViewID/${appViewId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFormViewByID(formViewId: string): Promise<EYDFormView> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFormView>>(`${this.BASE_URL}/GetFormViewByID/${formViewId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllListViews(): Promise<EYDListView[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDListView[]>>(`${this.BASE_URL}/GetAllListViews`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getListViewsByAppViewID(appViewId: string): Promise<EYDListView[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDListView[]>>(`${this.BASE_URL}/GetListViewsByAppViewID/${appViewId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getListViewByID(listViewId: string): Promise<EYDListView> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDListView>>(`${this.BASE_URL}/GetListViewByID/${listViewId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFormViewCustomFieldAssociationByFormViewID(FormViewId: string): Promise<EYDFormViewCustomFieldAssociation[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFormViewCustomFieldAssociation[]>>(`${this.BASE_URL}/getFormViewCustomFieldAssociationByFormViewID/${FormViewId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFormViewCustomFieldAssociationByID(id: string): Promise<EYDFormViewCustomFieldAssociation> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFormViewCustomFieldAssociation>>(`${this.BASE_URL}/getFormViewCustomFieldAssociationByID/${id}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllFieldDependency(): Promise<EYDCustomFieldDependency[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDCustomFieldDependency[]>>(`${this.BASE_URL}/GetAllFieldDependency`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllFieldDependenciesForFormView(formViewID:string): Promise<EYDCustomFieldDependency[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDCustomFieldDependency[]>>(`${this.BASE_URL}/getAllFieldDependenciesForFormView/${formViewID}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFieldDependencyByID(fldDepId: string): Promise<EYDCustomFieldDependency> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDCustomFieldDependency>>(`${this.BASE_URL}/GetFieldDependencyByID/${fldDepId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFieldLookupDependenciesByCustomFieldID(customFieldId: string): Promise<EYDFieldLookupDependency[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFieldLookupDependency[]>>(`${this.BASE_URL}/GetFieldLookupDependenciesByCustomFieldID/${customFieldId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getFieldLookupDependencyByID(lkpDepId: string): Promise<EYDFieldLookupDependency> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDFieldLookupDependency>>(`${this.BASE_URL}/GetFieldLookupDependencyByID/${lkpDepId}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getAllThemeSettings() : Promise<EYDThemeSetting[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDThemeSetting[]>>(`${this.BASE_URL}/GetAllThemeSettings`)
            .then((response) => resolve(response.data.value))
            .catch((error) => this.handleError(error, reject));
        });
    }

    getAllThemeSettingsByTargetId(depID: string) : Promise<EYDThemeSetting[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDThemeSetting[]>>(`${this.BASE_URL}/GetAllThemeSettingsByTargetId/${depID}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        })
    }
}

export default new AppConfigService();
