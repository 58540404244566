import { FieldTargetType, DynamicEntityFieldValue, DynamicEntityFieldType, FormViewType, FormActionType, ListViewType } from "eyam-webui-models";
import { EYDAppView, EYDAsset, EYDCustomField, EYDCustomFieldDependency, EYDCustomFieldId, EYDFormView, EYDFormViewCustomFieldAssociation, EYDLookupTableValue } from "eyd.models-ui";
import AppConfigService from "../../../../Services/AppConfigService";
import AssetsService from "../../../../Services/AssetsService";
import CustomFieldsService from "../../../../Services/CustomFieldsService";
import LookupTablesService from "../../../../Services/LookupTablesService";


export interface AssetFormConfigData {
    appView: EYDAppView,
    formView: EYDFormView,
    customFieldMap: Map<string, EYDCustomField>,
    fieldAssociations: EYDFormViewCustomFieldAssociation[],
    lookupValuesMap: Map<string, Map<string, EYDLookupTableValue>>,
    formFieldDependencies: EYDCustomFieldDependency[],
    usedFieldsList: string[]
}

export const loadAssetData = async (assetSN: string, fieldList: string[]): Promise<EYDAsset> => {
    let cAsset: EYDAsset;
    if (assetSN) {
        cAsset = await AssetsService.getAssetBySerial(assetSN, fieldList);
    } else {
        cAsset = new EYDAsset();
    }

    return cAsset;
}

export const loadAssetWithReuseData = (asset: EYDAsset, reuseVals: Map<string, DynamicEntityFieldValue>, customFieldMap: Map<string, EYDCustomField>) => {

    reuseVals.forEach((value, key) => {

        var cfDefDat = customFieldMap.get(key);
        if (cfDefDat) {

            if (!isFieldDataEmpty(reuseVals, cfDefDat)) {
                asset.fieldValues.set(key, value);
            }
        }
    });

    return asset
}


export const applyReuseValues = (assetVals: Map<string, DynamicEntityFieldValue>, reuseVals: Map<string, DynamicEntityFieldValue>, customFieldMap: Map<string, EYDCustomField>) => {

    reuseVals.forEach((value, key) => {

        var cfDefDat = customFieldMap.get(key);
        if (cfDefDat) {

            if (!isFieldDataEmpty(reuseVals, cfDefDat)) {
                assetVals.set(key, value);
            }
        }
    });
}

export const applyDataDefaults = (assetVals: Map<string, DynamicEntityFieldValue>, fieldAssociations: EYDFormViewCustomFieldAssociation[], customFieldMap: Map<string, EYDCustomField>) => {

    //apply default and overrides

    for (let cFieldAsoc of fieldAssociations) {

        var cfDefDat = customFieldMap.get(cFieldAsoc.customFieldID);

        if (cfDefDat) {

            let cVal = assetVals.has(cFieldAsoc.customFieldID) ? assetVals.get(cFieldAsoc.customFieldID) : null;

            if (cFieldAsoc.defaultValue && (cFieldAsoc.overrideWithDefaultValue || isFieldDataEmpty(assetVals, cfDefDat))) {

                let defOrOvrddVal = new DynamicEntityFieldValue();

                let ovrdVal: any = null;

                switch (cfDefDat.fieldType) {
                    case DynamicEntityFieldType.Boolean:
                        ovrdVal = (cFieldAsoc.defaultValue === 'true') || (cFieldAsoc.defaultValue === 'True');
                        break;
                    case DynamicEntityFieldType.Long:
                    case DynamicEntityFieldType.Integer:
                        let numVal = parseInt(cFieldAsoc.defaultValue);
                        ovrdVal = isNaN(numVal) ? 0 : numVal;
                        break;
                    case DynamicEntityFieldType.Float:
                        let numFltVal = parseFloat(cFieldAsoc.defaultValue);
                        ovrdVal = isNaN(numFltVal) ? 0 : numFltVal;
                        break;
                    case DynamicEntityFieldType.Date:
                        if (cFieldAsoc.defaultValue === 'NOW') {
                            ovrdVal = new Date().toISOString().substr(0, 10);
                        } else {
                            ovrdVal = cFieldAsoc.defaultValue;
                        }
                        break;
                    case DynamicEntityFieldType.Array:
                        ovrdVal = cFieldAsoc.defaultValue?.split(',')
                        break;

                    default:
                        ovrdVal = cFieldAsoc.defaultValue;
                        break;
                }

                defOrOvrddVal.setObjectValue(ovrdVal, cfDefDat.fieldType);
                assetVals.set(cFieldAsoc.customFieldID, defOrOvrddVal);
            }
        } else {
            throw "GENERIC_ERROR_ALERT-CUSTOM_FIELD_NOT_FOUND";
        }
    }

}

const isFieldDataEmpty = (assetVals: Map<string, DynamicEntityFieldValue>, cfDefData: EYDCustomField): boolean => {


    if (assetVals.has(cfDefData.customFieldID)) {

        let cVal = assetVals.get(cfDefData.customFieldID);

        if (cVal === null) return true;

        switch (cfDefData.fieldType) {
            case DynamicEntityFieldType.Boolean:
                return cVal.valueBoolean === null || cVal.valueBoolean === false;
            case DynamicEntityFieldType.Integer:
                return cVal.valueInt === null || cVal.valueInt === 0;
            case DynamicEntityFieldType.Long:
                return cVal.valueLong === null || cVal.valueLong === 0;
            case DynamicEntityFieldType.Float:
                return cVal.valueDouble === null || cVal.valueDouble === 0;
            case DynamicEntityFieldType.Date:
                return cVal.valueDateTime === null;
            case DynamicEntityFieldType.Array:
                return cVal.valueArray === null || (cVal.valueArray && cVal.valueArray.length === 0);
            case DynamicEntityFieldType.LongString:
            case DynamicEntityFieldType.String:
                return cVal.valueText === null || cVal.valueText.length === 0;
            case DynamicEntityFieldType.Lookup:
                return cVal.valueGuid === null;
        }

    }

    return true;
}

export const loadConfigurationData = async (appViewID: string): Promise<AssetFormConfigData> => {

    let cApView = await AppConfigService.getAppViewByID(appViewID);
    let cFormViews = await AppConfigService.getFormViewsByAppViewID(appViewID);

    if (cFormViews?.length == 0) {
        throw "GENERIC_ERROR_ALERT-LOADING_CONFIGURATION_FAILED"
    }

    let cFormView = cFormViews.find(fv => fv.viewType == FormViewType.AssetView);
    let asocs = await AppConfigService.getFormViewCustomFieldAssociationByFormViewID(cFormView.id);
    let fieldDeps = await AppConfigService.getAllFieldDependenciesForFormView(cFormView.id);

    let currentFieldList = asocs.map(a => a.customFieldID);

    for (let fldDep of fieldDeps) {
        if (!currentFieldList.some(cf => cf === fldDep.customFieldID)) {
            currentFieldList.push(fldDep.customFieldID);
        }

        if (!currentFieldList.some(cf => cf === fldDep.dependsOnCustomFieldID)) {
            currentFieldList.push(fldDep.dependsOnCustomFieldID);
        }
    }

    if (cApView.actions.some(a => a.actionType === FormActionType.AllowsCreate) && !currentFieldList.some(cf => cf === EYDCustomFieldId.SKU)) {
        currentFieldList.push(EYDCustomFieldId.SKU);
    }

    let currentFields = await CustomFieldsService.queryCustomFields({
        includeIDs: currentFieldList,
        targetType: FieldTargetType.Asset
    });

    let cfDict = new Map<string, EYDCustomField>();
    let lkpTablesToLoad: string[] = []

    for (let cf of currentFields) {
        cfDict.set(cf.customFieldID, cf);

        if (cf.lookupTableID && cf.lookupTableID != "00000000-0000-0000-0000-000000000000") {
            if (!lkpTablesToLoad.some(id => id == cf.lookupTableID)) {
                lkpTablesToLoad.push(cf.lookupTableID);
            }
        }
    }

    let lkpTableDicts = new Map<string, Map<string, EYDLookupTableValue>>();

    for (let lkpTblId of lkpTablesToLoad) {

        let cLkpTblValues = await LookupTablesService.getAllValuesForLookupTable(lkpTblId);
        let lkpMap = new Map<string, EYDLookupTableValue>();

        for (let lkpVal of cLkpTblValues) {
            lkpMap.set(lkpVal.id, lkpVal);
        }

        lkpTableDicts.set(lkpTblId, lkpMap);
    }

    return {
        appView: cApView,
        formView: cFormView,
        customFieldMap: cfDict,
        fieldAssociations: asocs,
        lookupValuesMap: lkpTableDicts,
        formFieldDependencies: fieldDeps,
        usedFieldsList: currentFieldList
    };
}

export const loadFormConfigurationForList = async (formViewId: string = null): Promise<AssetFormConfigData> => {

    let currentFields = await CustomFieldsService.getCustomFieldsByType(FieldTargetType.Asset);

    let cfDict = new Map<string, EYDCustomField>();
    let lkpTablesToLoad: string[] = []

    for (let cf of currentFields) {
        cfDict.set(cf.customFieldID, cf);

        if (cf.lookupTableID && cf.lookupTableID != "00000000-0000-0000-0000-000000000000") {
            if (!lkpTablesToLoad.some(id => id == cf.lookupTableID)) {
                lkpTablesToLoad.push(cf.lookupTableID);
            }
        }
    }

    let lkpTableDicts = new Map<string, Map<string, EYDLookupTableValue>>();

    for (let lkpTblId of lkpTablesToLoad) {

        let cLkpTblValues = await LookupTablesService.getAllValuesForLookupTable(lkpTblId);
        let lkpMap = new Map<string, EYDLookupTableValue>();

        for (let lkpVal of cLkpTblValues) {
            lkpMap.set(lkpVal.id, lkpVal);
        }

        lkpTableDicts.set(lkpTblId, lkpMap);
    }

    if (formViewId) {
        let cFormView = await AppConfigService.getFormViewByID(formViewId);
        let asocs = await AppConfigService.getFormViewCustomFieldAssociationByFormViewID(cFormView.id);
        let fieldDeps = await AppConfigService.getAllFieldDependenciesForFormView(cFormView.id);

        let currentFieldList = asocs.map(a => a.customFieldID);

        for (let fldDep of fieldDeps) {
            if (!currentFieldList.some(cf => cf === fldDep.customFieldID)) {
                currentFieldList.push(fldDep.customFieldID);
            }

            if (!currentFieldList.some(cf => cf === fldDep.dependsOnCustomFieldID)) {
                currentFieldList.push(fldDep.dependsOnCustomFieldID);
            }
        }

        return {
            appView: null,
            formView: cFormView,
            customFieldMap: cfDict,
            fieldAssociations: asocs,
            lookupValuesMap: lkpTableDicts,
            formFieldDependencies: fieldDeps,
            usedFieldsList: currentFieldList
        };
    }

    return {
        appView: null,
        formView: null,
        customFieldMap: cfDict,
        fieldAssociations: [],
        lookupValuesMap: lkpTableDicts,
        formFieldDependencies: [],
        usedFieldsList: []
    };
}

export const isLocationValid = (entityValues: Map<string, DynamicEntityFieldValue>): boolean => {
    const latitude = entityValues.get(EYDCustomFieldId.Latitude)?.getObjectValue(DynamicEntityFieldType.Float);
    const longitude = entityValues.get(EYDCustomFieldId.Longitude)?.getObjectValue(DynamicEntityFieldType.Float)
    return latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
}

export const checkFieldNullorEmpty = (fieldValue: DynamicEntityFieldValue, type: DynamicEntityFieldType) => {

    let value = fieldValue?.getObjectValue(type)

    if (value !== null && value !== undefined) {

        switch (type) {
            case DynamicEntityFieldType.String:
            case DynamicEntityFieldType.LongString:
            case DynamicEntityFieldType.Date:
            case DynamicEntityFieldType.Lookup:
            case DynamicEntityFieldType.Array:
                value = value as string;
                if (value.length > 0) {
                    return false;
                }
                break;
            case DynamicEntityFieldType.Boolean:
            case DynamicEntityFieldType.Integer:
            case DynamicEntityFieldType.Float:
            case DynamicEntityFieldType.Long:
                return false;
        };
    }

    return true;

}