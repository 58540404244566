import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { CountStatus, EYDItemCount, EYDItemCountDetail, EYDItemCountSession } from "eyd.models-ui";
import { EYAMApiResult } from "eyam-webui-models";

class ItemCountService extends BaseService {
    private readonly BASE_URL: string = "/api/ItemCount";

    getItemCountSessions(locationID: number): Promise<EYDItemCountSession[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemCountSession[]>>(`${this.BASE_URL}/GetItemCountSessions/${locationID}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getItemCountSession(countSessionID: number, expandDetails: boolean = false): Promise<EYDItemCountSession> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemCountSession>>(`${this.BASE_URL}/GetItemCountSession/${countSessionID}/${expandDetails}`, {
                params: { expandDetails }
            })
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getItemCount(countID: number, expandDetails: boolean = false): Promise<EYDItemCount> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemCount>>(`${this.BASE_URL}/GetItemCount/${countID}/${expandDetails}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertItemCount(data: EYDItemCount): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<number>>(`${this.BASE_URL}/UpsertItemCount`, data)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getItemCountDetails(countID: number): Promise<EYDItemCountDetail> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<EYDItemCountDetail>>(`${this.BASE_URL}/GetItemCountDetails/${countID}`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    upsertItemCountDetail(data: EYDItemCountDetail): Promise<number> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<number>>(`${this.BASE_URL}/UpsertItemCountDetail`, data)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

}

export default new ItemCountService();
